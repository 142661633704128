<template>
  <div class="video">
    <div class="search">
      <el-input
        class="input"
        v-model="input"
        placeholder="请输入视频"
        prefix-icon="el-icon-search"
      ></el-input>
      <div class="sea" @click="search">搜索</div>
    </div>

    <div class="video-content">
      <div class="scroll">
        <div
          class="video-item"
          v-for="(item, index) in videoItems"
          :key="index"
        >
          <video
            :src="item.video"
            controls="controls"
            width="250px"
            height="140px"
          ></video>
          <div class="video-title">{{ item.title }}</div>
          <div class="time">{{ item.time }}</div>
          <div class="vider-description">{{ item.description }}</div>
          <div class="video-author">
            <div class="video-author-name" @click="toUser(item.author)">
              <img :src="item.headImg" alt="" width="20px" height="20px" />{{
                item.author
              }}
            </div>
            <div class="follow" @click="follow(item.author)">关注</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import * as echarts from "echarts";
import $ from "jquery";
export default {
  data() {
    return {
      input: "",
      videoItems: [
        {
          id: "",
          video: "",
          title: "",
          time: "",
          description: "",
          author: "",
          headImg: "",
        },
      ],
    };
  },
  mounted() {
    this.getVideoItems();
  },
  methods: {
    //跳转到个人详情
    toUser(author) {
      // 将数据作为查询字符串参数传递
      this.$router.push({
        path: "/personalDetail",
        query: { author: author },
      });
    },
    //关注方法
    follow(author) {
      //关注数加一
      this.$message({ message: "关注成功", type: "success" });
      // 调用关注接口
      axios
        .post("/api/follow", {
          author: author,
        })
        .then((response) => {
          // 处理关注成功后的逻辑
          console.log(response.data);
        })
        .catch((error) => {
          // 处理关注失败后的逻辑
          console.error(error);
        });
    },
    //获取用户头像
    async getInfo(username) {
      try {
        const res = await axios.get("http://47.93.13.70:8081/user/show");
        for (var i = 0; i <= res.data.length - 1; i++) {
          if (username == res.data[i].username) {
            return res.data[i].avatar;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    //获取视频列表数据
    async getVideoItems() {
      try {
        const res = await $.ajax({
          type: "get",
          url: "http://47.93.13.70:8081/video/show",
          contentType: "application/json;charset=utf-8",
          dataType: "json",
        });
        this.videoItems = [];
        for (var i = 0; i < res.length; i++) {
          if (res[i].state === "发布中") {
            // 添加条件判断
            const headImg = await this.getInfo(res[i].publisher);
            this.videoItems.push({
              video: res[i].video,
              id: res[i].id,
              title: res[i].name,
              time: res[i].time,
              description: res[i].introduction,
              author: res[i].publisher,
              headImg: headImg,
            });
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 搜索方法
    async search() {
      var name = this.input;
      try {
        const res = await $.ajax({
          type: "get",
          url: "http://47.93.13.70:8081/video/searchone",
          contentType: "application/json;charset=utf-8",
          dataType: "json",
          data: { name },
        });
        this.videoItems = [];
        for (var i = 0; i < res.length; i++) {
          if (res[i].state === "发布中") {
            // 添加条件判断
            const headImg = await this.getInfo(res[i].publisher);
            this.videoItems.push({
              video: res[i].video,
              title: res[i].name,
              description: res[i].introduction,
              author: res[i].publisher,
              headImg: headImg,
            });
          }
        }
        if (this.videoItems.length === 0) {
          this.$message.error("该视频不存在哦！");
          return;
        }
      } catch (error) {
        alert(JSON.stringify(error));
      }
    },
  },
};
</script>

<style lang="less" scoped>
.search {
  width: 1100px;
  height: 50px;
  margin-top: 20px;
  margin-left: 360px;
  margin-bottom: 20px;
}

.input {
  display: block;
  width: 600px;
  height: 50px;
  float: left;
}

.sea {
  width: 80px;
  height: 45px;
  float: left;
  text-align: center;
  line-height: 45px;
  font-size: 16px;
  color: white;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  background-color: rgb(1, 175, 174);
  cursor: pointer;
  /* 设置光标样式为小手 */
}

.time {
  font-size: 12px;
  margin-top: 2px;
  font-weight: 400;
}

/deep/.el-input__inner {
  height: 45px;
  line-height: 45px;
  border: 2px solid rgb(1, 175, 174);
  border-radius: 0;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
}

/deep/.el-input__inner:focus {
  border-color: rgb(1, 175, 174);
}

.video-content {
  width: 1100px;
  height: 530px;
  margin: auto;
  padding-left: 20px;
  overflow: auto;
  /* 启用滚动条 */
}

.video-item {
  width: 260px;
  height: 255px;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
}

.video-item video {
  border-radius: 5px;
  border: 1px solid gainsboro;
}

.video-author {
  width: 260px;
  height: 30px;
  display: flex;
  align-items: center;
  line-height: 30px;
  font-size: 14px;
  margin-bottom: 5px;
}

.video-author-name {
  height: 30px;
  line-height: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  /* 设置光标样式为小手 */
}

.vider-description {
  font-size: 13px;
  height: 30px;
  width: 255px;
  margin: auto;
  margin-top: 5px;
}

.video-title {
  font-weight: 600;
  font-size: 17px;
}

.video-like {
  float: left;
}

.follow {
  width: 40px;
  height: 20px;
  line-height: 20px;
  background-color: sandybrown;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  margin-left: 10px;
  cursor: pointer;
  /* 设置光标样式为小手 */
}

.likeImg {
  margin-right: 15px;
}

.icon-clicked {
  color: red;
}
</style>
