<template>
  <div class="personalCenter">
    <div class="personalLeft">
      <div class="img">
        <img
          :src="personalInfo.headImg"
          alt=""
          width="150px"
          height="150px"
          style="border-radius: 50%"
        />
        <el-upload
          class="avatar-uploader"
          action="#"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
        >
          <el-link ref="avatarLink" type="info" style="font-size: 5px"
            >修改头像</el-link
          >
        </el-upload>
      </div>

      <div class="a">我是</div>
      <div class="b">{{ this.username }}</div>
      <div class="favorite">
        <div class="num">{{ personalInfo.favorite }}</div>
        <div class="im">
          <img src="favorite.png" alt="" width="20px" height="20px" />收藏
        </div>
      </div>
      <div class="follow">
        <div class="num">{{ personalInfo.follow }}</div>
        <div class="im">
          <img src="follow.png" alt="" width="20px" height="20px" />关注
        </div>
      </div>
      <div class="funs">
        <div class="num">{{ personalInfo.funs }}</div>
        <div class="im">
          <img src="funs.png" alt="" width="20px" height="20px" />粉丝
        </div>
      </div>
      <el-divider></el-divider>

      <div class="c">
        <div @click="getFaceInfo">
          <img src="getFace.png" alt="" width="50px" height="50px" />
        </div>
        <div>面部信息采集</div>
      </div>
    </div>
    <div class="personalRight">
      <el-descriptions class="margin-top" :column="2" :size="midium" border>
        <template slot="extra">
          <el-button type="warning" size="small" @click="editPersonal"
            >完善/修改</el-button
          >
        </template>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            用户名
          </template>
          {{ personalInfo.name }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-key"></i>
            密码
          </template>
          {{ personalInfo.password }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-key"></i>
            性别
          </template>
          {{ personalInfo.sex }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-key"></i>
            出生日期
          </template>
          {{ personalInfo.date }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-location-outline"></i>
            手机号
          </template>
          {{ personalInfo.phone }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            专业
          </template>
          <el-tag size="small"> {{ personalInfo.major }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-picture-outline-round"></i>
            面部识别
          </template>
          {{ personalInfo.face }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            地区
          </template>
          {{ personalInfo.place }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            简介
          </template>
          {{ personalInfo.description }}
        </el-descriptions-item>
      </el-descriptions>
    </div>

    <div class="opDialog">
      <el-dialog title="个人信息" :visible.sync="dialogFormVisible">
        <el-form :model="editPersonalInfo">
          <el-form-item label="用户名：" :label-width="formLabelWidth">
            <el-input
              v-model="editPersonalInfo.name"
              autocomplete="off"
              readonly
            ></el-input>
          </el-form-item>
          <el-form-item label="密码：" :label-width="formLabelWidth">
            <el-input
              v-model="editPersonalInfo.password"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="性别：" :label-width="formLabelWidth">
            <el-radio v-model="editPersonalInfo.sex" label="男">男</el-radio>
            <el-radio v-model="editPersonalInfo.sex" label="女">女</el-radio>
          </el-form-item>
          <el-form-item label="出生日期：" :label-width="formLabelWidth">
            <div class="block">
              <el-date-picker
                v-model="editPersonalInfo.date"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </div>
          </el-form-item>
          <el-form-item label="手机号：" :label-width="formLabelWidth">
            <el-input
              v-model="editPersonalInfo.phone"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="专业：" :label-width="formLabelWidth">
            <el-input
              v-model="editPersonalInfo.major"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="地区：" :label-width="formLabelWidth">
            <el-input
              v-model="editPersonalInfo.place"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="简介：" :label-width="formLabelWidth">
            <el-input
              v-model="editPersonalInfo.description"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="editPersonalInfoA">确 定</el-button>
        </div>
      </el-dialog>

      <div class="face_box">
        <el-dialog title="面部信息采集" :visible.sync="faceDialogVisible">
          <video
            ref="video"
            width="246"
            height="185"
            autoplay
            class="video"
          ></video>
          <div class="face_text">请将面部正对</div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="faceDialogVisible = false">取 消</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import * as echarts from "echarts";
import $ from "jquery";
export default {
  data() {
    return {
      username: "", //用户名
      avatarUrl: "", //头像
      //表单数据
      faceDialogVisible: false,
      dialogFormVisible: false,
      //个人信息数据
      personalInfo: {
        id: "",
        headImg: "",
        favorite: "",
        follow: "",
        funs: "",
        name: "",
        password: "",
        sex: "",
        date: "",
        phone: "",
        major: "",
        face: "",
        place: "",
        description: "",
      },
      //编辑表单数据
      editPersonalInfo: {
        id: "",
        name: "",
        password: "",
        sex: "男",
        date: "",
        phone: "",
        major: "",
        place: "",
        description: "",
      },
      formLabelWidth: "100px", //表单域的宽度
    };
  },
  mounted() {
    this.username = localStorage.getItem("username");
    // 页面加载时检查是否存在永久颜色，如果存在则应用该颜色
    const avatarLinkColor = localStorage.getItem("avatarLinkColor");
    if (avatarLinkColor) {
      this.$refs.avatarLink.style.color = avatarLinkColor;
    }
    this.getPersonalInfo();
  },
  computed: {},
  methods: {
    handleAvatarSuccess(response, file, fileList) {
      // 处理头像上传成功后的逻辑
      // 获取上传成功后的头像链接，并保存在组件数据中
      this.avatarUrl = response.data.avatarUrl;
      // 修改链接颜色为永久颜色
      this.$refs.avatarLink.style.color = "rgb(1, 175, 174)";
      // 还可以将新颜色保存在本地存储中，以便在页面重新加载时保持颜色不变
      localStorage.setItem("avatarLinkColor", "rgb(1, 175, 174)");
    },
    formattedDate() {
      // 使用JavaScript的Date对象来处理日期，并将其格式化为所需的样式
      if (!this.editPersonalInfo.date) return ""; // 确保日期非空
      const dateObj = new Date(this.editPersonalInfo.date);
      const year = dateObj.getFullYear();
      const month = String(dateObj.getMonth() + 1).padStart(2, "0");
      const day = String(dateObj.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    editPersonal() {
      this.editPersonalInfo.id = this.personalInfo.id;
      this.editPersonalInfo.name = this.personalInfo.name;
      this.editPersonalInfo.password = this.personalInfo.password;
      this.editPersonalInfo.sex = this.personalInfo.sex;
      this.editPersonalInfo.date = this.personalInfo.date;
      this.editPersonalInfo.phone = this.personalInfo.phone;
      this.editPersonalInfo.major = this.personalInfo.major;
      this.editPersonalInfo.place = this.personalInfo.place;
      this.editPersonalInfo.description = this.personalInfo.description;
      this.dialogFormVisible = "true";
    },
    //编辑确定按钮
    editPersonalInfoA() {
      var id = this.editPersonalInfo.id;
      var username = this.editPersonalInfo.name;
      var password = this.editPersonalInfo.password;
      var sex = this.editPersonalInfo.sex;
      var birthdate = this.formattedDate();
      var phone = this.editPersonalInfo.phone;
      var profession = this.editPersonalInfo.major;
      var region = this.editPersonalInfo.place;
      var resume = this.editPersonalInfo.description;
      console.log(
        id,
        username,
        password,
        sex,
        birthdate,
        phone,
        profession,
        region,
        resume
      );
      $.ajax({
        type: "get",
        url: "http://47.93.13.70:8081/user/update",
        contentType: "application/json;charset=utf-8",
        dataType: "text",
        data: {
          id,
          username,
          password,
          sex,
          birthdate,
          phone,
          profession,
          region,
          resume,
        },
        success: (res) => {
          this.$message.success("修改成功！");
          this.getPersonalInfo();
        },
        error: function (err) {
          alert(JSON.stringify(err));
        },
      });
      // 关闭弹窗
      this.dialogFormVisible = false;
    },
    //获取个人信息
    getPersonalInfo() {
      $.ajax({
        type: "get",
        url: "http://47.93.13.70:8081/user/show",
        contentType: "application/json;charset=utf-8",
        dataType: "json",
        success: (res) => {
          var Face = "否";
          const username = localStorage.getItem("username");
          const userInfo = res.find((item) => item.username === username);
          if (userInfo.isfaced === 1) {
            Face = "是";
          }
          if (userInfo) {
            this.personalInfo = {
              id: userInfo.id,
              headImg: userInfo.avatar,
              funs: userInfo.fan,
              follow: userInfo.focus,
              favorite: userInfo.collection,
              name: userInfo.username,
              password: userInfo.password,
              sex: userInfo.sex,
              date: userInfo.birthdate,
              phone: userInfo.phone,
              major: userInfo.profession,
              face: Face,
              place: userInfo.region,
              description: userInfo.resume,
            };
            console.log(this.personalInfo);
          }
        },
        error: function (err) {
          alert(JSON.stringify(err));
        },
      });
    },
    async captureFace() {
      const formData = new FormData();
      let stream;
      try {
        // 获取用户摄像头的视频流
        stream = await navigator.mediaDevices.getUserMedia({ video: true });
        this.$refs.video.srcObject = stream;
        // 延迟一段时间，确保视频流已经加载完毕
        await new Promise((resolve) => setTimeout(resolve, 1000));
        // 创建一个canvas元素来绘制捕获的视频帧
        const video = this.$refs.video;
        const canvas = document.createElement("canvas");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        // 将捕获的面部信息转换为文件对象
        canvas.toBlob(async (blob) => {
          formData.append("fileImage", blob, "face_image.png");
          formData.append("username", this.username);
          console.log(formData.get("username"));
          // 发送POST请求将数据提交到后端接口
          $.ajax({
            type: "post",
            url: "http://47.93.13.70:8081/face/add",
            contentType: false, // jQuery不要去设置Content-Type请求头
            processData: false, // jQuery不要去处理发送的数据
            data: formData,
            success: (res) => {
              this.$message.success("面部信息上传成功！");
              // 关闭视频流，并释放资源
              if (stream) {
                const tracks = stream.getTracks();
                tracks.forEach((track) => track.stop());
              }
              this.$refs.video.srcObject = null; // 清空视频源对象
              this.faceDialogVisible = false;
            },
            error: (res) => {
              this.$message.error("面部信息上传失败，请检查！");
            },
          });
        }, "image/png");
      } catch (error) {
        this.$message.error("面部信息采集失败，请检查！");
      }
    },
    //面部信息采集
    getFaceInfo() {
      this.faceDialogVisible = true;
      this.captureFace();
    },
  },
};
</script>

<style lang="less" scoped>
.personalCenter {
  margin: auto;
  margin-top: 50px;
  width: 1000px;
  height: 500px;
  border-radius: 15px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.personalLeft {
  float: left;
  width: 30%;
  height: 100%;
  background-color: rgb(244, 234, 221);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.img {
  width: 150px;
  height: 150px;
  margin: auto;
  margin-top: 20px;
}

.avatar-uploader {
  text-align: center;
}

.a {
  width: 100%;
  height: 30px;
  font-size: 20px;
  text-align: center;
  margin-top: 40px;
  font-weight: 600;
}

.b {
  width: 100%;
  height: 30px;
  font-size: 25px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 25px;
  color: rgb(12, 123, 123);
}

.c {
  text-align: center;
  margin-top: 0px;
  cursor: pointer;
  /* 设置光标样式为小手 */
}

.favorite {
  text-align: center;
  float: left;
  margin-right: 30px;
  margin-left: 40px;
}

.follow {
  text-align: center;
  float: left;
  margin-right: 30px;
}

.funs {
  text-align: center;
  float: left;
  margin-right: 30px;
}

.im {
  display: flex;
  align-items: center;
}

.el-divider--horizontal {
  margin-top: 70px;
}

.personalRight {
  float: right;
  width: 70%;
  height: 100%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: whitesmoke;
}

.margin-top {
  width: 600px;
  text-align: center;
  margin: auto;
  margin-top: 60px;
}

.el-form-item {
  margin-bottom: 10px;
}

.opDialog form {
  margin: auto;
}

/deep/.el-dialog {
  width: 550px;
  border-radius: 10px;
}

/deep/.el-dialog__header {
  padding: 0;
  padding-top: 10px;
  text-align: center;
}

/deep/.el-dialog__title {
  font-size: 18px;
  font-weight: 600;
  color: rgb(12, 123, 123);
}

/deep/.el-dialog__body {
  padding: 0px 20px;
  margin: auto;
  margin-top: 20px;
  margin-left: 55px;
}

/deep/.opDialog input {
  width: 250px;
}

.face {
  display: flex;
  align-items: center;
  margin-left: 80px;
  margin-top: 30px;
  cursor: pointer;
  /* 设置光标样式为小手 */
}

.video {
  border-radius: 50%;
  border: 1px solid gainsboro;
  margin-left: 65px;
}

.face_text {
  text-align: center;

  height: 20px;
  margin-bottom: 10px;
}

/deep/.face_box .el-dialog {
  width: 420px;
  border-radius: 10px;
}

/deep/.face_box .el-dialog__header {
  padding: 0;
  padding-top: 10px;
  text-align: center;
}

/deep/.face_box .el-dialog__title {
  font-size: 18px;
  font-weight: 600;
  color: rgb(12, 123, 123);
}

/deep/.face_box .el-dialog__body {
  padding: 0px 20px;
  margin: auto;
  margin-top: 20px;
}
</style>
