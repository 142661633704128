<template>
  <div class="login">
    <div class="login_box">
      <h2>智能案例教学平台</h2>
      <div class="role">
        <div id="student" @click="login('student')">我是用户</div>
        <div id="admin" @click="login('admin')">我是管理员</div>
      </div>
      <el-form
        ref="loginFormRef"
        :model="loginForm"
        :rules="loginRules"
        class="login_form"
      >
        <el-form-item prop="username">
          <el-input
            v-model="loginForm.username"
            prefix-icon="el-icon-user-solid"
            style="opacity: 0.95"
            placeholder="请输入账号"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="loginForm.password"
            prefix-icon="el-icon-lock"
            style="opacity: 0.95"
            placeholder="请输入密码"
            type="password"
            show-password="true"
          ></el-input>
        </el-form-item>

        <el-checkbox id="check" v-model="checked"
          >已阅读并同意 <b>用户协议</b> 和 <b>隐私政策</b></el-checkbox
        >
        <el-button class="but1" type="success" round @click="loginBut"
          >登录</el-button
        >
        <router-link to="/register" class="link"
          ><el-link type="info">注册</el-link></router-link
        >
        <div class="face" @click="getFaceInfo">
          <img src="face.png" alt="" width="20px" height="20px" />人脸识别登录
        </div>
      </el-form>
    </div>
    <div class="face_box">
      <el-dialog title="人脸识别登录" :visible.sync="dialogFormVisible">
        <video
          ref="video"
          width="246"
          height="185"
          autoplay
          class="video"
        ></video>
        <div class="face_text">请将面部正对</div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
export default {
  data() {
    return {
      role: "student",
      checked: false,
      isFace: false,
      dialogFormVisible: false,
      //登录表单的数据对象
      loginForm: {
        username: "",
        password: "",
      },
      //验证规则
      loginRules: {
        username: [
          { required: true, message: "请输入账号", trigger: "blur" },
          {
            min: 3,
            max: 12,
            message: "账号的长度需要在3~9个字符",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 4,
            max: 10,
            message: "密码的长度需要在4~10个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {},
  methods: {
    //确定登录对象
    login(role) {
      if (role === "student") {
        this.role = "student";
        document.getElementById("student").style.backgroundColor = "white";
        document.getElementById("admin").style.backgroundColor = "transparent";
        document.getElementById("student").style.color = "rgb(1, 174, 173)";
        document.getElementById("admin").style.color = "black";
      } else if (role === "admin") {
        this.role = "admin";
        document.getElementById("admin").style.backgroundColor = "white";
        document.getElementById("student").style.backgroundColor =
          "transparent";
        document.getElementById("admin").style.color = "rgb(1, 174, 173)";
        document.getElementById("student").style.color = "black";
      }
    },

    async captureFace() {
      const formData = new FormData();
      let stream;
      try {
        // 获取用户摄像头的视频流
        stream = await navigator.mediaDevices.getUserMedia({ video: true });
        this.$refs.video.srcObject = stream;
        // 延迟一段时间，确保视频流已经加载完毕
        await new Promise((resolve) => setTimeout(resolve, 1000));
        // 创建一个canvas元素来绘制捕获的视频帧
        const video = this.$refs.video;
        const canvas = document.createElement("canvas");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        // 将捕获的面部信息转换为文件对象
        canvas.toBlob(async (blob) => {
          formData.append("fileImage", blob, "face_image.png");
          // 发送POST请求将数据提交到后端接口
          $.ajax({
            type: "post",
            url: "http://47.93.13.70:8081/face/log",
            contentType: false, // jQuery不要去设置Content-Type请求头
            processData: false, // jQuery不要去处理发送的数据
            data: formData,
            success: (res) => {
              if (res.sorce > 85) {
                localStorage.setItem("username", res.username);
                this.$message.success("面部识别登录成功！");
                // 使用 nextTick 确保视图更新
                this.$nextTick(() => {
                  this.$router.push({ path: "/home" });
                  return;
                });
              } else {
                this.$message.error("未采集面部信息，请先采集面部信息！");
              }
            },
            error: (err) => {
              console.log(err);
              this.$message.error("未识别人脸信息，请调整姿势！");
            },
          });
        }, "image/png");

        // 关闭视频流，并释放资源
        if (stream) {
          const tracks = stream.getTracks();
          tracks.forEach((track) => track.stop());
        }
        this.$refs.video.srcObject = null; // 清空视频源对象
        this.dialogFormVisible = false;
      } catch (error) {
        console.error("Error capturing face:", error);
      }
    },
    getFaceInfo() {
      this.dialogFormVisible = true;
      this.captureFace();
    },

    //登录前的预验证
    loginBut(credentials) {
      //this.$router.push({ path: "/home" }); return;
      this.$refs.loginFormRef.validate(async (valid) => {
        // 验证非空与长度
        if (!valid) {
          return;
        }
        //验证用户条款是否勾选
        if (!this.checked) {
          this.$message({
            message: "请你同意用户条款！",
            type: "error",
            duration: "1000",
            showClose: "true",
          });
          return;
        }
        if (this.role === "student") {
          // 验证账号密码是否存在且正确
          const response = await axios.get("http://47.93.13.70:8081/user/show");
          const res = response.data;
          console.log(res);
          for (var i = 0; i <= res.length - 1; i++) {
            if (
              this.loginForm.username == res[i].username &&
              this.loginForm.password == res[i].password
            ) {
              localStorage.setItem("username", this.loginForm.username);
              localStorage.setItem("headImg", res[i].avatar);
              localStorage.setItem("userid", res[i].id);
              this.$message.success("登录成功！");
              this.$router.push({ path: "/Home" });
              return;
            }
          }
          this.$message({
            message: "账号或密码错误！",
            type: "error",
            duration: "1000",
            showClose: "true",
          });
          return;
        } else if (this.role === "admin") {
          // 验证账号密码是否存在且正确
          if (
            this.loginForm.username == "admin" &&
            this.loginForm.password == "admin"
          ) {
            localStorage.setItem("username", this.loginForm.username);
            this.$message.success("登录成功！");
            this.$router.push({ path: "/adminHome" });
            return;
          }
          // const { data: res } = await this.$http.get('http://localhost:8081/user/show', credentials);
          // for (var i = 0; i <= res.length - 1; i++) {
          //     if (this.loginForm.username == res[i].username && this.loginForm.password == res[i].password) {
          //         localStorage.setItem('username', this.loginForm.username);
          //         this.$message.success("登录成功！"); this.$router.push({ path: "/adminHome" });return;
          //     }
          // }
          this.$message({
            message: "账号或密码错误！",
            type: "error",
            duration: "1000",
            showClose: "true",
          });
          return;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background: url(../Photo/login.jpg) no-repeat center;
}

.role {
  width: 275px;
  margin: auto;
  height: 40px;
  padding: 4px;
  border-radius: 15px;
  background-color: rgb(241, 241, 245);
  margin-top: 30px;
  line-height: 40px;
}

#student {
  width: 50%;
  height: 40px;
  float: left;
  border-radius: 8px;
  color: rgb(1, 174, 173);
  background-color: white;
  font-weight: 550;
  cursor: pointer;
  /* 设置光标样式为小手 */
}

#admin {
  width: 50%;
  height: 40px;
  float: right;
  border-radius: 8px;
  font-weight: 550;
  cursor: pointer;
  /* 设置光标样式为小手 */
}

.login_box {
  width: 400px;
  height: 430px;
  margin: auto;
  background-color: aliceblue;
  border-radius: 10px;
  text-align: center;
}

.login_box h2 {
  margin-top: 20px;
}

.login_form {
  width: 280px;
  margin: auto;
  margin-top: 20px;
}

/deep/.el-input__inner {
  border-radius: 10px;
}

/deep/.el-form-item {
  margin-bottom: 20px;
}

.el-checkbox {
  color: #000;
}

.but1 {
  margin-top: 15px;
  width: 280px;
  border-radius: 8px !important;
}

.link {
  float: right;
  margin-right: 5px;
}

.face {
  display: flex;
  align-items: center;
  margin-left: 80px;
  margin-top: 30px;
  cursor: pointer;
  /* 设置光标样式为小手 */
}

.video {
  border-radius: 50%;
  border: 1px solid gainsboro;
  margin-left: 65px;
  padding: 0;
}

.face_text {
  text-align: center;
  height: 20px;
  margin-bottom: 10px;
}

/deep/.el-dialog {
  width: 420px;
  border-radius: 10px;
}

/deep/.el-dialog__header {
  padding: 0;
  padding-top: 10px;
  text-align: center;
}

/deep/.el-dialog__title {
  font-size: 18px;
  font-weight: 600;
  color: rgb(12, 123, 123);
}

/deep/.el-dialog__body {
  padding: 0px 20px;
  margin: auto;
  margin-top: 20px;
}
</style>
