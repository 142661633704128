<template>
    <div class="homePage" style="height: 100%;">
        <div class="main">
            <div class="header">
                <div class="title">
                    <img src="logo1.png" alt="" width="40px" height="25px">
                    智能案例教学平台
                </div>
                <div class="user">
                    <el-avatar src="admin.png"></el-avatar>
                    <div class="username">
                        <el-dropdown trigger="click">
                            <span class="el-dropdown-link">
                                {{ username }}<i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">                       
                                   <div @click="exit"> <el-dropdown-item icon="el-icon-circle-close" >退出登录</el-dropdown-item>   </div>                         
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>
            </div>

            <div class="content">
                <div class="conLeft">
                    <div class="leftNav">
                        <router-link to="/caseManage" class="nav"> <i class="el-icon-s-order"></i>案例管理</router-link>
                        <router-link to="/videoManage" class="nav"> <i
                                class="el-icon-video-camera-solid"></i>视频管理</router-link>
                        <router-link to="/usersManage" class="nav"> <i class="el-icon-s-custom"></i>用户管理</router-link>
                    </div>
                </div>
                <div class="conRight">
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { color } from 'echarts';
export default {
    data() {
        return {
            username: 'admin',
        }
    },
    mounted() {

    },
    methods: {
        exit(){
                this.$router.push('/login');
                localStorage.removeItem('username');  
                localStorage.removeItem('userid');
        }
    },

}
</script>

<style lang='less' scoped>
.homePage {
    background-color: rgb(214, 229, 226);
    display: flex;
    align-items: center;
}

.main {
    width: 1400px;
    height: 670px;
    background-color: white;
    margin: auto;
    border-radius: 8px;
}

.header {
    width: 100%;
    height: 50px;
    line-height: 50px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 1px solid;
}

.title {
    width: 205px;
    float: left;
    color: rgb(1, 175, 174);
    font-weight: 550;
    font-size: 18px;
    display: flex;
    align-items: center;
    background-color: black;
    border-top-left-radius: 10px;
    padding-left: 15px;
}

.user {
    float: right;
    margin-right: 20px;
    color: black;
}

.el-avatar {
    margin-top: 8px;
    margin-right: 5px;
    width: 35px;
    height: 35px;
}

.username {
    float: right;
cursor: pointer;
    /* 设置光标样式为小手 */
}


.el-dropdown-link {
    color: rgb(1, 175, 174);
    font-weight: 600;
}


.content {
    width: 100%;
    height: 620px;
}

.conLeft {
    width: 220px;
    height: 100%;
    border-bottom-left-radius: 8px;
    background-color: black;
    float: left;
}

.conRight {
    width: 1180px;
    height: 98%;
    float: right;
}

.leftNav {
    width: 220px;
    height: 500px;
    margin-top: 20px;
}

.nav {
    display: block;
    width: 220px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: white;
    font-size: 17px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.nav:hover {

    color: rgb(1, 175, 174);

}
</style>
