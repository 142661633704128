<template>
  <div class="personalDetail">
    <div class="author">
      <div class="author-img">
        <img
          :src="personalInfo.headImg"
          alt=""
          width="80px"
          height="80px"
          class="img"
        />
      </div>
      <div class="author-name">
        <div class="naG">
          <div class="name">{{ this.author }}</div>
          <div class="guanzhu" @click="follow(this.author)">关注</div>
        </div>
        <div class="fff">
          <div class="follow">
            <div class="im">
              <img src="follow.png" alt="" width="20px" height="20px" />关注:
              {{ personalInfo.follow }}
            </div>
          </div>
          <div class="funs">
            <div class="im">
              <img src="funs.png" alt="" width="20px" height="20px" />粉丝:
              {{ personalInfo.funs }}
            </div>
          </div>
          <div class="favorite">
            <div class="im">
              <img src="favorite.png" alt="" width="20px" height="20px" />收藏:
              {{ personalInfo.favorite }}
            </div>
          </div>
        </div>
        <div class="description">
          <span>个人简介：</span>
          {{ personalInfo.description }}
        </div>
        <div class="from">
          <span>来自：</span>
          {{ personalInfo.from }}
        </div>
      </div>
    </div>
    <div class="his">
      <img src="his.png" alt="" width="25px" height="25px" />
      他的作品
    </div>
    <div class="hisCase">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="案例" name="first">
          <div class="scroll">
            <el-col :span="24" class="caseList">
              <el-card
                shadow="hover"
                v-for="(item, index) in cardItems"
                :key="index"
              >
                <div @click="toCaseDetail(item.id)">
                  <div class="left">
                    <img
                      :src="item.fenImg"
                      alt=""
                      width="130px"
                      height="130px"
                    />
                  </div>
                  <div class="right">
                    <div>
                      <div class="caseName">{{ item.caseName }}</div>
                      <div class="caseTime">{{ item.caseTime }}</div>
                    </div>
                    <div class="caseDescription">
                      {{ item.caseDescription }}
                    </div>
                    <div class="caseLike">
                      <div
                        @click.stop="toggleLike(item.id, item.caseName)"
                        class="likeImg"
                        :class="{ 'icon-clicked': isLiked(item.id) }"
                      >
                        <i class="el-icon-thumb"></i>
                      </div>
                      <div
                        @click.stop="store(item.id, item.caseName)"
                        class="likeImg"
                        :class="{ 'icon-clicked': isStored(item.id) }"
                      >
                        <i class="el-icon-star-off"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </el-card>
            </el-col>
          </div>
        </el-tab-pane>
        <el-tab-pane label="视频" name="second">
          <div class="video-content">
            <div
              class="video-item"
              v-for="(item, index) in videoItems"
              :key="index"
            >
              <video
                :src="item.video"
                controls="controls"
                width="230px"
                height="130px"
              ></video>
              <div class="like">
                <div class="video-title">{{ item.title }}</div>
              </div>
              <div class="time">{{ item.time }}</div>
              <div class="vider-description">{{ item.description }}</div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import * as echarts from "echarts";
import $ from "jquery";
export default {
  data() {
    return {
      author: "",
      activeName: "first",
      userid: "", //用户名
      likedItems: [],
      storedItems: [],
      personalInfo: {
        id: "",
        headImg: "",
        follow: "",
        funs: "",
        from: "",
        description: "",
        favorite: "",
      },
      cardItems: [
        {
          id: "",
          caseName: "",
          caseTime: "",
          caseDescription: "",
          fenImg: "",
        },
      ],
      videoItems: [
        {
          id: "",
          video: "",
          title: "",
          description: "",
        },
      ],
    };
  },
  mounted() {
    this.userid = localStorage.getItem("userid");
    this.loadFromLocalStorage();
    this.getPersonalInfo();
    this.getCaseInfo();
    this.getVideoInfo();
  },
  created() {
    //获取路由参数
    this.author = this.$route.query.author;
  },
  // ...其他选
  methods: {
    //跳转到案例详情
    toCaseDetail(id) {
      // 将数据作为查询字符串参数传递
      this.$router.push({
        path: "/caseDetail",
        query: { id: id },
      });
    },
    //关注方法
    follow(author) {
      //关注数加一
      this.$message({ message: "关注成功", type: "success" });
      // 调用关注接口
      axios
        .post("/api/follow", {
          author: author,
        })
        .then((response) => {
          // 处理关注成功后的逻辑
          console.log(response.data);
        })
        .catch((error) => {
          // 处理关注失败后的逻辑
          console.error(error);
        });
    },
    //获得个人信息表数据
    getPersonalInfo() {
      $.ajax({
        type: "get",
        url: "http://47.93.13.70:8081/user/show",
        contentType: "application/json;charset=utf-8",
        dataType: "json",
        success: (res) => {
          var username = this.author;
          const userInfo = res.find((item) => item.username === username);
          if (userInfo) {
            this.personalInfo = {
              id: userInfo.id,
              headImg: userInfo.avatar,
              //headImg: 'head.jpg',
              follow: userInfo.focus,
              funs: userInfo.fan,
              from: userInfo.region,
              description: userInfo.resume,
              favorite: userInfo.collection,
              //favorite: '2000',
            };
          }
        },
        error: function (err) {
          alert(JSON.stringify(err));
        },
      });
    },
    //获得案例表数据
    getCaseInfo() {
      $.ajax({
        type: "get",
        url: "http://47.93.13.70:8081/case/show",
        contentType: "application/json;charset=utf-8",
        dataType: "json",
        success: (res) => {
          var username = this.author;
          this.cardItems = res
            .filter((item) => item.publisher === username)
            .map((item) => ({
              id: item.id,
              caseName: item.name,
              caseTime: item.casetime,
              caseDescription: item.introduction,
              fenImg: item.cover,
              //fenImg: 'fa.jpg',
            }));
          if (this.cardItems.length === 0) {
            this.$message.warning("作者还没有发布案例！下次再来吧！");
          }
        },
        error: function (err) {
          alert(JSON.stringify(err));
        },
      });
    },
    //获得视频表数据
    getVideoInfo() {
      $.ajax({
        type: "get",
        url: "http://47.93.13.70:8081/video/show",
        contentType: "application/json;charset=utf-8",
        dataType: "json",
        success: (res) => {
          var username = this.author;
          this.videoItems = res
            .filter((item) => item.publisher === username)
            .map((item) => ({
              id: item.id,
              title: item.name,
              description: item.introduction,
              time: item.time,
              video: item.video,
            }));
        },
        error: function (err) {
          alert(JSON.stringify(err));
        },
      });
    },
    //获得关注表数据
    caseLike(id) {
      // 调用关注表接口
    },

    //点赞方法
    toggleLike(itemId, caseName) {
      // 检查当前项是否已经被点赞
      if (this.isLiked(itemId)) {
        const index = this.likedItems.indexOf(itemId);
        if (index !== -1) {
          this.likedItems.splice(index, 1);
          var useid = this.userid;
          var name = caseName;
          $.ajax({
            type: "get",
            url: "http://47.93.13.70:8081/dianzan/deletecase",
            contentType: "application/json;charset=utf-8",
            data: {
              useid,
              name,
            },
            success: (res) => {
              this.$message.error("已取消点赞！");
            },
            error: function (err) {
              alert(JSON.stringify(err));
            },
          });
        }
      } else {
        this.likedItems.push(itemId);
        //添加赞数
        var useid = this.userid;
        var name = caseName;
        console.log(name);
        $.ajax({
          type: "get",
          url: "http://47.93.13.70:8081/dianzan/addcase",
          contentType: "application/json;charset=utf-8",
          data: {
            useid,
            name,
          },
          success: (res) => {
            //alert("添加成功！");
            this.$message.success("作者谢谢你的支持^_^");
          },
          error: function (err) {
            alert(JSON.stringify(err));
          },
        });
      }
      localStorage.setItem("likedItems", JSON.stringify(this.likedItems));
    },
    isLiked(itemId) {
      return this.likedItems.includes(itemId);
    },

    isLiked(itemId) {
      return this.likedItems.includes(itemId);
    },

    store(itemId, caseName) {
      // 执行收藏的操作
      // 保存到本地存储
      if (this.isStored(itemId)) {
        const index = this.storedItems.indexOf(itemId);
        if (index !== -1) {
          this.storedItems.splice(index, 1);
          //取消收藏
          var useid = this.userid;
          var name = caseName;
          $.ajax({
            type: "get",
            url: "http://47.93.13.70:8081/collection/deletecase",
            contentType: "application/json;charset=utf-8",
            data: {
              useid,
              name,
            },
            success: (res) => {
              this.$message.error("已取消收藏！");
            },
            error: function (err) {
              alert(JSON.stringify(err));
            },
          });
        }
      } else {
        this.storedItems.push(itemId);
        //添加收藏
        var useid = this.userid;
        var name = caseName;
        console.log(name);
        $.ajax({
          type: "get",
          url: "http://47.93.13.70:8081/collection/addcase",
          contentType: "application/json;charset=utf-8",
          data: {
            useid,
            name,
          },
          success: (res) => {
            //alert("添加成功！");
            this.$message.success("收藏成功^_^");
          },
          error: function (err) {
            alert(JSON.stringify(err));
          },
        });
      }
      localStorage.setItem("storedItems", JSON.stringify(this.storedItems));
    },
    isStored(itemId) {
      return this.storedItems.includes(itemId);
    },
    loadFromLocalStorage() {
      this.likedItems = JSON.parse(localStorage.getItem("likedItems")) || [];
      this.storedItems = JSON.parse(localStorage.getItem("storedItems")) || [];
    },
  },
};
</script>

<style lang="less" scoped>
.personalDetail {
  width: 1200px;
  height: 600px;
  margin: auto;
}

.author {
  width: 1000px;
  height: 150px;
  background-color: antiquewhite;
  margin: auto;
  margin-top: 40px;
  border-radius: 5px;
}

.author-img {
  float: left;
  width: 130px;
  height: 150px;
  position: relative;
}

.author-img .img {
  border-radius: 50%;
  position: absolute;
  top: -20px;
  right: 30px;
}

.author-name {
  float: left;
  width: 800px;
  height: 150px;
}

.naG {
  height: 35px;
}

.name {
  font-size: 19px;
  margin-top: 10px;
  font-weight: 600;
  float: left;
}

.guanzhu {
  float: left;
  width: 50px;
  height: 20px;
  margin-left: 30px;
  margin-top: 13px;
  line-height: 20px;
  text-align: center;
  border-radius: 10px;
  font-size: 13px;
  background-color: sandybrown;
  cursor: pointer;
  /* 设置光标样式为小手 */
}

.fff {
  margin-top: 10px;
  height: 21px;
}

.favorite {
  text-align: center;
  float: left;
  margin-right: 30px;
}

.follow {
  text-align: center;
  float: left;
  margin-right: 30px;
}

.funs {
  text-align: center;
  float: left;
  margin-right: 30px;
}

.im {
  display: flex;
  align-items: center;
}

.description {
  height: 21px;
  margin-top: 13px;
  font-size: 13px;
}

.from {
  height: 21px;
  margin-top: 5px;
  font-size: 13px;
}

.his {
  width: 1000px;
  height: 30px;
  margin: auto;
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.hisCase {
  width: 1000px;
  height: 370px;
  margin: auto;
}

.time {
  font-size: 12px;
  margin-top: 2px;
  font-weight: 400;
}

.scroll {
  width: 1017px;
  height: 330px;
  overflow: auto;
  /* 启用滚动条 */
}

.caseList {
  /* 设置容器的固定高度 */
  width: 1000px;
  height: 330px;
}

/deep/.el-card__body {
  padding: 10px;
}

.el-card:hover {
  /* 边框颜色变化 */
  background-color: #f6faf1;
  /* 背景色变化 */
}

.left {
  width: 140px;
  height: 140px;
  float: left;
}

.right {
  width: 815px;
  height: 140px;
  margin-left: 5px;
  float: left;
}

.caseName {
  font-size: 17px;
  font-weight: 600;
}

.caseDescription {
  height: 60px;
  margin-top: 5px;
  font-size: 13px;
}

.caseTime {
  font-size: 13px;
  margin-top: 5px;
}

.caseLike {
  display: flex;
  align-items: center;
}

.likeImg {
  margin-right: 10px;
}

.video-content {
  width: 1007px;
  height: 300px;
  margin: auto;
  padding-left: 10px;
  margin-top: 20px;
  overflow: auto;

  /* 启用滚动条 */
}

.video-item {
  width: 230px;
  height: 240px;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
}

.video-item video {
  border-radius: 5px;
  border: 1px solid gainsboro;
}

.vider-description {
  font-size: 13px;
  height: 55px;
  width: 255px;
  margin: auto;
  margin-top: 5px;
}

.video-title {
  font-weight: 600;
  float: left;
  margin-right: 20px;
}

.like {
  display: flex;
  align-items: center;
  font-size: 17px;
}

.likeImg {
  margin-right: 15px;
}

.icon-clicked {
  color: red;
}
</style>
