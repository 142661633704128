import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../components/Home.vue'
import Login from '../components/Login.vue'
import Register from '../components/Register.vue'
import Video from '../components/subpage/users/teachVideo.vue'
import CaseEx from '../components/subpage/users/caseExperence.vue'
import CaseStudy from '../components/subpage/users/caseStudy.vue'
import Assistant from '../components/subpage/users/assistant.vue'
import MyPost from '../components/subpage/users/myPost.vue'
import MyPostCase from '../components/subpage/users/myPostCase.vue'
import MyPostVideo from '../components/subpage/users/myPostVideo.vue'
import PersonalCenter from '../components/subpage/users/personalCenter.vue'
import PostCase from '../components/subpage/users/postCase.vue'
import PostVideo from '../components/subpage/users/postVideo.vue'
import AdminHome from '@/components/subpage/admin/adminHome.vue'
import CaseManage from '@/components/subpage/admin/caseManage.vue'
import VideoManage from '@/components/subpage/admin/videoManage.vue'
import UserSManage from '@/components/subpage/admin/usersManage.vue'
import CaseDetail from '@/components/subpage/users/caseDetail.vue'
import personalDetail from '@/components/subpage/users/personalDetail.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    component: Home,
    redirect: '/caseStudy',
    children: [
      {
        path: "/caseStudy",
        component: CaseStudy,
      },
      {
        path: "/video",
        component: Video,
      },
      {
        path: "/caseEx",
        component: CaseEx,
      },
      {
        path: "/assistant",
        component: Assistant,
      },
      {
        path: "/caseDetail",
        component: CaseDetail,
      },
      {
        path: "/personalDetail",
        component: personalDetail,
      },
      {
        path: "/myPost",
        component: MyPost,
        children: [
          {
            path: "/myPost/myPostCase",
            component: MyPostCase,
          },
          {
            path: "/myPost/myPostVideo",
            component: MyPostVideo,
          }
        ]
      },
      {
        path: "/personalCenter",
        component: PersonalCenter,
      },
      {
        path: "/postCase",
        component: PostCase,
      },
      {
        path: "/postVideo",
        component: PostVideo,
      },
    ]
  },
  { path: '/', redirect: '/login' },
  { path: '/login', component: Login },
  { path: '/register', component: Register },
  {
    path: '/adminHome',
    component: AdminHome,
    redirect: '/caseManage',
    children: [
      {
        path: "/caseManage",
        component: CaseManage,
      },
      {
        path: "/videoManage",
        component: VideoManage,
      },
      {
        path: "/usersManage",
        component: UserSManage,
      },
    ]
  },
];

const router = new VueRouter({
  routes
})

export default router
