<template>
    <div class="caseExperence">
        <el-empty :image-size="300"></el-empty>
    </div>
</template>



<script>
import axios from 'axios';
import * as echarts from 'echarts';
import $ from 'jquery';
export default {
    data() {
        return {
            dialogFormVisible: false,
        }
    },
    mounted() {

    },
    methods: {

    }

}
</script>

<style lang='less' scoped>
.el-empty {
    width: 100%;
    margin-top: 50px;
}
</style>