import { render, staticRenderFns } from "./postVideo.vue?vue&type=template&id=83013754&scoped=true&"
import script from "./postVideo.vue?vue&type=script&lang=js&"
export * from "./postVideo.vue?vue&type=script&lang=js&"
import style0 from "./postVideo.vue?vue&type=style&index=0&id=83013754&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83013754",
  null
  
)

export default component.exports