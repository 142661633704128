<template>
  <div class="register">
    <div class="register_box">
      <h2>智能案例教学平台</h2>
      <el-form
        ref="registerFormRef"
        :model="registerForm"
        :rules="registerRules"
        label-width="90px"
        class="register_form"
      >
        <el-form-item prop="username" label="账号 :">
          <el-input
            v-model="registerForm.username"
            prefix-icon="el-icon-user-solid"
            style="opacity: 0.9"
            placeholder="请输入账号"
          >
          </el-input>
        </el-form-item>

        <el-form-item prop="password" label="密码 :">
          <el-input
            v-model="registerForm.password"
            prefix-icon="el-icon-lock"
            style="opacity: 0.95"
            placeholder="请输入密码"
            type="password"
            show-password="true"
          ></el-input>
        </el-form-item>

        <el-form-item prop="confirm_password" label="确认密码 :">
          <el-input
            v-model="registerForm.confirm_password"
            prefix-icon="el-icon-lock"
            style="opacity: 0.95"
            placeholder="请确认密码"
            type="password"
            show-password="true"
          ></el-input>
        </el-form-item>

        <el-form-item prop="major" label="专业 :">
          <el-input
            v-model="registerForm.major"
            prefix-icon="el-icon-lock"
            style="opacity: 0.95"
            placeholder="请输入专业"
          ></el-input>
        </el-form-item>
        <el-form-item prop="number" label="手机号码 :">
          <el-input
            v-model="registerForm.number"
            prefix-icon="el-icon-phone-outline"
            style="opacity: 0.95"
            placeholder="请输入手机号码"
          >
          </el-input>
        </el-form-item>
        <el-checkbox v-model="checked"
          >已阅读并同意 <b>用户协议</b> 和 <b>隐私政策</b></el-checkbox
        >
        <el-form-item class="register_but" label-width="0px">
          <el-button class="but1" type="success" round @click="registerBut"
            >注册
          </el-button>
          <router-link to="/login">
            <el-button class="but2" type="info" round>返回 </el-button>
          </router-link>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  data() {
    return {
      checked: false,
      registerForm: {
        username: "",
        password: "",
        confirm_password: "",
        major: "",
        number: "",
      },
      //验证规则
      registerRules: {
        username: [
          { required: true, message: "请输入账号", trigger: "blur" },
          {
            min: 3,
            max: 9,
            message: "账号的长度需要在3~9个字符",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 4,
            max: 10,
            message: "密码的长度需要在4~10个字符",
            trigger: "blur",
          },
        ],
        confirm_password: [
          { required: true, message: "请确认密码", trigger: "blur" },
          {
            min: 4,
            max: 10,
            message: "密码的长度需要在6~10个字符",
            trigger: "blur",
          },
        ],
        number: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            min: 11,
            max: 11,
            message: "手机的长度需要11位数字",
            trigger: "blur",
          },
        ],
        major: [
          { required: true, message: "请输入专业", trigger: "blur" },
          {
            min: 2,
            max: 9,
            message: "专业的长度需要在2~9个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },

  methods: {
    registerBut() {
      this.$refs.registerFormRef.validate(async (valid) => {
        //验证是否为空与长度
        if (!valid) {
          return;
        }
        //验证密码是否相同
        if (this.registerForm.password !== this.registerForm.confirm_password) {
          this.$message({
            message: "两次输入的密码不一样！",
            type: "error",
            duration: "1000",
            showClose: "true",
          });
          return;
        }
        //验证是否勾选用户条款
        if (!this.checked) {
          this.$message({
            message: "请你同意用户条款！",
            type: "error",
            duration: "1000",
            showClose: "true",
          });
          return;
        }
        //验证是否用户名已存在
        const { data: res } = await this.$http.get(
          "http://47.93.13.70:8081/user/show"
        );
        for (var i = 0; i <= res.length - 1; i++) {
          if (this.registerForm.username == res[i].username) {
            this.$message({
              message: "账号已存在请更换！",
              type: "error",
              duration: "1000",
              showClose: "warnning",
            });
            return;
          }
        }
        //注册
        $.ajax({
          type: "get",
          url: "http://47.93.13.70:8081/user/add",
          contentType: "application/json;charset=utf-8",
          dataType: "text",
          data: {
            username: this.registerForm.username,
            password: this.registerForm.password,
            profession: this.registerForm.major,
            phone: this.registerForm.number,
          },
        }).then(() => {
          this.$message({
            message: "恭喜你，注册成功了！3秒后将跳转到登录页面。！",
            type: "success",
            duration: "1500",
            showClose: "true",
          });
          //设置跳转时间间隔
          setTimeout(() => {
            this.$router.push({ path: "/login" });
          }, 2000);
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.register {
  height: 100%;
  width: 100%;
  background: url(../Photo/login.jpg) no-repeat center;
  display: flex;
  align-items: center;
}

.register_box {
  width: 470px;
  height: 500px;
  margin: auto;
  background-color: aliceblue;
  margin: auto;
  border-radius: 10px;
  text-align: center;
}

.register_box h2 {
  margin-top: 20px;
}

.el-select {
  margin-bottom: 22px;
  margin-left: 24px;
}

i {
  margin-left: 55px;
}

.register_form {
  position: absolute;
  bottom: 120px;
  margin-left: 60px;
}

.el-checkbox {
  color: #000;
}

.but1 {
  margin-top: 15px;
}

.but2 {
  margin-top: 15px;
  float: right;
  display: block;
  margin-right: 5px;
}
</style>
