<template>
  <div id="app">
    <router-view :key="$route.fullPath"> </router-view>
  </div>
</template>

<script>

export default {
  name: 'app',
  provide() {
    return {
      //  reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true
    }
  },
  
}
</script>

<style>

</style>
