<template>
  <div class="videoManage">
    <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
      <el-breadcrumb-item :to="{ path: '/adminHome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>视频管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <div style="margin-bottom: 15px">
        <el-input
          placeholder="请输入视频ID"
          size="small"
          v-model="inputId"
          style="width: 150px; margin-right: 10px"
          clearable
        >
        </el-input>
        <el-input
          placeholder="请输入视频名称"
          size="small"
          v-model="inputName"
          style="width: 150px; margin-right: 10px"
          clearable
        >
        </el-input>
        <el-button
          type="primary"
          size="small"
          icon="el-icon-search"
          @click="search"
          >搜索</el-button
        >
      </div>
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick" type="card">
      <el-tab-pane label="全部" name="first">
        <div class="concent">
          <el-table
            ref="filterTable"
            :data="tableData.slice(startIndex, endIndex)"
            style="width: 100%"
          >
            <el-table-column prop="id" label="视频编号" width="80">
            </el-table-column>
            <el-table-column
              prop="name"
              label="视频名称"
              width="110"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="author" label="发布者" width="120px">
            </el-table-column>
            <el-table-column
              prop="dscription"
              label="视频简介"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="time" label="发布时间" width="100px">
            </el-table-column>
            <el-table-column prop="video" label="视频文件" width="180px">
              <template slot-scope="scope">
                <video
                  :src="scope.row.video"
                  controls="controls"
                  width="150px"
                  height="90px"
                ></video>
              </template>
            </el-table-column>
            <el-table-column
              prop="tag"
              label="状态"
              width="90"
              :filters="[
                { text: '待审核', value: '待审核' },
                { text: '已下架', value: '已下架' },
                { text: '发布中', value: '发布中' },
              ]"
              :filter-method="filterTag"
              filter-placement="bottom-end"
            >
              <template slot-scope="scope">
                <el-tag :type="getTagType(scope.row.tag)" disable-transitions>{{
                  scope.row.tag
                }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="warning"
                  @click="handleExamine(scope.$index, scope.row)"
                  >审核</el-button
                >
                <el-button
                  size="mini"
                  type="info"
                  @click="handleOff(scope.$index, scope.row)"
                  >下架</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.$index, scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :page-size="pageSize"
            :total="this.tableData.length"
            :current-page.sync="currentPage"
            @current-change="handlePageChange"
            layout="prev, pager, next"
          >
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="发布中" name="second">
        <div class="concent">
          <el-table
            ref="filterTable"
            :data="publishedData.slice(startIndexP, endIndexP)"
            style="width: 100%"
          >
            <el-table-column prop="id" label="编号" width="80">
            </el-table-column>
            <el-table-column
              prop="name"
              label="视频名称"
              width="110"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="author" label="发布者" width="140px">
            </el-table-column>
            <el-table-column
              prop="dscription"
              label="视频简介"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="time" label="发布时间" width="100px">
            </el-table-column>
            <el-table-column prop="video" label="视频文件" width="180px">
              <template slot-scope="scope">
                <video
                  :src="scope.row.video"
                  controls="controls"
                  width="150px"
                  height="90px"
                ></video>
              </template>
            </el-table-column>
            <el-table-column prop="tag" label="状态" width="100">
              <template slot-scope="scope">
                <el-tag :type="getTagType(scope.row.tag)" disable-transitions>{{
                  scope.row.tag
                }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="info"
                  @click="handleOff(scope.$index, scope.row)"
                  >下架</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.$index, scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :page-size="pageSize"
            :total="this.publishedData.length"
            :current-page.sync="currentPageP"
            @current-change="handlePageChangeP"
            layout="prev, pager, next"
          >
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="待审核" name="third">
        <div class="concent">
          <el-table
            ref="filterTable"
            :data="reviewData.slice(startIndexR, endIndexR)"
            style="width: 100%"
          >
            <el-table-column prop="id" label="编号" width="80">
            </el-table-column>
            <el-table-column
              prop="name"
              label="视频名称"
              width="110"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="author" label="发布者" width="140px">
            </el-table-column>
            <el-table-column
              prop="dscription"
              label="视频简介"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="time" label="发布时间" width="100px">
            </el-table-column>
            <el-table-column prop="video" label="视频文件" width="180px">
              <template slot-scope="scope">
                <video
                  :src="scope.row.video"
                  controls="controls"
                  width="150px"
                  height="90px"
                ></video>
              </template>
            </el-table-column>
            <el-table-column prop="tag" label="状态" width="100">
              <template slot-scope="scope">
                <el-tag :type="getTagType(scope.row.tag)" disable-transitions>{{
                  scope.row.tag
                }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  warning
                  type="warning"
                  @click="handleExamine(scope.$index, scope.row)"
                  >审核</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.$index, scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :page-size="pageSize"
            :total="this.reviewData.length"
            :current-page.sync="currentPageR"
            @current-change="handlePageChangeR"
            layout="prev, pager, next"
          >
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="已下架" name="fourth">
        <div class="concent">
          <el-table
            ref="filterTable"
            :data="offlineData.slice(startIndexO, endIndexO)"
            style="width: 100%"
          >
            <el-table-column prop="id" label="编号" width="80">
            </el-table-column>
            <el-table-column
              prop="name"
              label="视频名称"
              width="110"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="author" label="发布者" width="140px">
            </el-table-column>
            <el-table-column
              prop="dscription"
              label="视频简介"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="time" label="发布时间" width="100px">
            </el-table-column>
            <el-table-column prop="video" label="视频文件" width="180px">
              <template slot-scope="scope">
                <video
                  :src="scope.row.video"
                  controls="controls"
                  width="150px"
                  height="90px"
                ></video>
              </template>
            </el-table-column>
            <el-table-column prop="tag" label="状态" width="100">
              <template slot-scope="scope">
                <el-tag :type="getTagType(scope.row.tag)" disable-transitions>{{
                  scope.row.tag
                }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.$index, scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :page-size="pageSize"
            :total="this.offlineData.length"
            :current-page.sync="currentPageO"
            @current-change="handlePageChangeO"
            layout="prev, pager, next"
          >
          </el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import axios from "axios";
import * as echarts from "echarts";
import $ from "jquery";
export default {
  data() {
    return {
      inputId: "", //搜索视频Id
      inputName: "", //搜索视频名
      //分页
      currentPage: 1, // 全部当前页码
      currentPageP: 1, // 已发布当前页码
      currentPageR: 1, // 审核中当前页码
      currentPageO: 1, // 已下架当前页码
      pageSize: 3, // 每页显示的行数
      activeName: "first",
      publishedData: [], // 存放发布中的数据
      reviewData: [], // 存放审核中的数据
      offlineData: [], // 存放已下架的数据
      tableData: [],
    };
  },
  computed: {
    //全部分页功能
    startIndex() {
      // 计算起始位置
      return (this.currentPage - 1) * this.pageSize;
    },
    //已发布分页功能
    startIndexP() {
      // 计算起始位置
      return (this.currentPageP - 1) * this.pageSize;
    },
    //待审核分页功能
    startIndexR() {
      // 计算起始位置
      return (this.currentPageR - 1) * this.pageSize;
    },
    //已下架分页功能
    startIndexO() {
      // 计算起始位置
      return (this.currentPageO - 1) * this.pageSize;
    },
    endIndex() {
      // 计算全部结束位置
      return this.startIndex + this.pageSize;
    },
    endIndexP() {
      // 计算全部结束位置
      return this.startIndexP + this.pageSize;
    },
    endIndexR() {
      // 计算全部结束位置
      return this.startIndexR + this.pageSize;
    },
    endIndexO() {
      // 计算全部结束位置
      return this.startIndexO + this.pageSize;
    },
  },
  mounted() {
    this.getTagType();
    this.getTableData();
  },
  created() {
    this.getTagType();
    this.getTableData();
  },
  methods: {
    handlePageChange(page) {
      // 处理分页器页码变化事件
      this.currentPage = page;
    },
    handlePageChangeP(page) {
      // 处理分页器页码变化事件
      this.currentPageP = page;
    },
    handlePageChangeR(page) {
      // 处理分页器页码变化事件
      this.currentPageR = page;
    },
    handlePageChangeO(page) {
      // 处理分页器页码变化事件
      this.currentPageO = page;
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    formatter(row, column) {
      return row.address;
    },

    filterHandler(value, row, column) {
      const property = column["property"];
      return row[property] === value;
    },

    //状态筛选
    filterTag(value, row) {
      return row.tag === value;
    },
    //设置状态颜色
    getTagType(status) {
      if (status === "待审核") {
        return "warning"; // 或者其他合适的样式类型
      } else if (status === "发布中") {
        return "success"; // 或者其他合适的样式类型
      } else if (status === "已下架") {
        return "danger"; // 或者其他合适的样式类型
      } else {
        return "info"; // 其他状态的默认样式类型
      }
    },
    //获取数据
    getTableData() {
      $.ajax({
        type: "get",
        url: "http://47.93.13.70:8081/video/show",
        contentType: "application/json;charset=utf-8",
        dataType: "json",
        success: (res) => {
          this.tableData = [];
          for (var i = 0; i < res.length; i++) {
            this.tableData.push({
              id: res[i].id,
              name: res[i].name,
              author: res[i].publisher,
              dscription: res[i].introduction,
              time: res[i].time,
              video: res[i].video,
              tag: res[i].state,
            });
          }
          this.getOtherData();
        },
        error: (res) => {
          this.$message.error("数据获取失败！");
        },
      });
    },

    getOtherData() {
      // 在这里过滤出发布中的数据并赋值给 publishedData
      this.publishedData = this.tableData.filter(
        (item) => item.tag === "发布中"
      );
      // 在这里过滤出审核中的数据并赋值给 reviewData
      this.reviewData = this.tableData.filter((item) => item.tag === "待审核");
      // 在这里过滤出已下架的数据并赋值给 offlineData
      this.offlineData = this.tableData.filter((item) => item.tag === "已下架");
    },

    //搜索操作
    search() {
      var id = this.inputId;
      var name = this.inputName;
      $.ajax({
        type: "get",
        url: "http://47.93.13.70:8081/video/searchtwo",
        contentType: "application/json;charset=utf-8",
        dataType: "json",
        data: {
          id,
          name,
        },
        success: (res) => {
          var data = JSON.stringify(res);
          data = JSON.parse(data);
          this.tableData = [];
          for (var i = 0; i < data.length; i++) {
            //console.log(data[i].name)
            this.tableData.push({
              id: res[i].id,
              name: res[i].name,
              author: res[i].publisher,
              dscription: res[i].introduction,
              time: res[i].time,
              video: res[i].video,
              tag: res[i].state,
            });
          }
        },
        error: (res) => {
          this.$message.error("该视频不存在！");
        },
      });
    },
    //删除操作
    handleDelete(index, row) {
      var id = row.id;
      // 弹出确认删除的对话框
      if (confirm("确认要删除这条视频？")) {
        $.ajax({
          type: "get",
          url: "http://47.93.13.70:8081/video/delete",
          contentType: "application/json;charset=utf-8",
          data: { id },
          success: (res) => {
            this.$message.success("删除成功！");
            this.getTableData();
          },
          error: (res) => {
            this.$message.error("删除失败，请检查！");
          },
        });
      } else {
        this.$message.info("删除操作已取消！");
      }
    },
    //审核操作
    handleExamine(index, row) {
      var id = row.id;
      var name = row.name;
      var introduction = row.dscription;
      var ostate = row.tag;
      var state = "发布中";
      //console.log(ostate)
      if (ostate == "发布中") {
        this.$message.success("该视频发布中，无需再审核！");
      } else if (ostate == "已下架") {
        this.$message.success("该视频已下架！");
      } else if (ostate == "待审核") {
        if (confirm("确认通过审核？")) {
          $.ajax({
            type: "get",
            url: "http://47.93.13.70:8081/video/update",
            contentType: "application/json;charset=utf-8",
            dataType: "text",
            data: {
              id,
              name,
              introduction,
              state,
            },
            success: (res) => {
              this.$message.success("审核通过！");
              this.getTableData();
            },
            error: (res) => {
              this.$message.error("审核失败，请检查！");
            },
          });
        } else {
          this.$message.info("审核操作已取消！");
        }
      }
    },
    //下架操作
    handleOff(index, row) {
      var id = row.id;
      var name = row.name;
      var introduction = row.dscription;
      var ostate = row.tag;
      var state = "已下架";
      //console.log(ostate)
      if (ostate == "已下架") {
        this.$message.success("该视频已下架，无需重复操作！");
      } else {
        if (confirm("确认下架？")) {
          $.ajax({
            type: "get",
            url: "http://47.93.13.70:8081/video/update",
            contentType: "application/json;charset=utf-8",
            dataType: "text",
            data: {
              id,
              name,
              introduction,
              state,
            },
            success: (res) => {
              this.$message.success("下架成功！");
              this.getTableData();
            },
            error: (res) => {
              this.$message.error("下架败，请检查！");
            },
          });
        } else {
          this.$message.info("下架操作已取消！");
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.breadcrumb {
  margin-bottom: 20px;
  margin-top: 10px;
}

.videoManage {
  width: 95%;
  margin: auto;
}

.concent {
  width: 100%;
  margin: auto;
  height: 480px;
}

/deep/.el-tabs__item.is-active {
  color: rgb(1, 175, 174);
}

/deep/.el-tabs__item:hover {
  color: rgb(1, 175, 174);
}
</style>
