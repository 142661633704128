<template>
  <div class="caseDetail">
    <div class="caseDetailMain">
      <div class="caseDetail-title">{{ this.caseInfo.caseName }}</div>
      <div class="caseDetail-author">
        <img
          src="yuanchuang.png"
          alt=""
          width="35px"
          height="35px"
          class="author-img"
        />
        <div class="author" @click="toUser(caseInfo.author)">
          {{ caseInfo.author }}
        </div>
        <div class="time">发布于：{{ caseInfo.time }}</div>
        <div class="view">
          <i class="el-icon-view"> 浏览量：{{ caseInfo.view }}</i>
        </div>
        <div class="likes">
          <i class="el-icon-thumb"> 获赞量：{{ caseInfo.likes }}</i>
        </div>
      </div>
      <div class="caseDescription">
        <h3>简介:</h3>
        <p>{{ caseInfo.description }}</p>
      </div>
      <div class="caseFile" @click="preview">
        <img src="pdf.png" alt="" width="120px" height="100px" class="pdf" />
      </div>
      <div class="downLoad" @click="downloadFile">下载文档</div>
      <div class="score">
        <el-rate
          v-model="value"
          show-score
          text-color="#ff9900"
          score-template="{ value }"
        >
        </el-rate>
      </div>
      <div class="like">
        <div
          @click.stop="toggleLike(caseInfo.id, caseInfo.caseName)"
          class="likeImg"
          :class="{ 'icon-clicked': isLiked(caseInfo.id) }"
        >
          <i class="el-icon-thumb"></i>
        </div>
        <div
          @click.stop="store(caseInfo.id, caseInfo.caseName)"
          class="likeImg"
          :class="{ 'icon-clicked': isStored(caseInfo.id) }"
        >
          <i class="el-icon-star-off"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import * as echarts from "echarts";
import $ from "jquery";
export default {
  data() {
    return {
      id: "",
      value: "0", //案例评分
      userid: "", //用户名
      likedItems: [],
      storedItems: [],
      caseInfo: {
        id: "001",
        caseName: "",
        author: "",
        time: "",
        view: "",
        likes: "",
        description: "",
        file: "",
      },
    };
  },
  mounted() {
    this.userid = localStorage.getItem("userid");
    this.loadFromLocalStorage();
    this.getCaseInfo();
  },
  created() {
    //获取路由参数
    this.id = this.$route.query.id;
  },
  // ...其他选
  methods: {
    //跳转到个人详情
    toUser(author) {
      // 将数据作为查询字符串参数传递
      this.$router.push({
        path: "/personalDetail",
        query: { author: author },
      });
    },

    //获取案例信息
    getCaseInfo() {
      var id = this.id;
      var name = "";
      var num = 0;
      $.ajax({
        type: "get",
        url: "http://47.93.13.70:8081/case/searchtwo",
        contentType: "application/json;charset=utf-8",
        dataType: "json",
        data: {
          id,
          name,
        },
        success: (res) => {
          this.caseInfo.id = res[0].id;
          this.caseInfo.caseName = res[0].name;
          this.caseInfo.author = res[0].publisher;
          this.caseInfo.time = res[0].casetime;
          this.caseInfo.view = res[0].scan;
          this.caseInfo.likes = res[0].likes;
          this.caseInfo.description = res[0].introduction;
          this.caseInfo.file = res[0].casefile;
        },
        error: function (err) {
          alert(JSON.stringify(err));
        },
      });
    },

    //点赞方法
    toggleLike(itemId, caseName) {
      if (this.isLiked(itemId)) {
        const index = this.likedItems.indexOf(itemId);
        if (index !== -1) {
          this.likedItems.splice(index, 1);
          var useid = this.userid;
          console.log(useid);
          var name = caseName;
          $.ajax({
            type: "get",
            url: "http://47.93.13.70:8081/dianzan/deletecase",
            contentType: "application/json;charset=utf-8",
            data: {
              useid,
              name,
            },
            success: (res) => {
              this.$message.error("已取消点赞！");
              this.getCaseInfo();
            },
            error: function (err) {
              alert(JSON.stringify(err));
            },
          });
        }
      } else {
        this.likedItems.push(itemId);
        //添加赞数
        var useid = this.userid;
        var name = caseName;
        console.log(1111);
        console.log(useid);
        console.log(name);
        $.ajax({
          type: "get",
          url: "http://47.93.13.70:8081/dianzan/addcase",
          contentType: "application/json;charset=utf-8",
          data: {
            useid,
            name,
          },
          success: (res) => {
            //alert("添加成功！");
            this.$message.success("作者谢谢你的支持^_^");
            this.getCaseInfo();
          },
          error: function (err) {
            alert(JSON.stringify(err));
          },
        });
      }
      localStorage.setItem("likedItems", JSON.stringify(this.likedItems));
    },
    isLiked(itemId) {
      return this.likedItems.includes(itemId);
    },
    store(itemId, caseName) {
      // 执行收藏的操作
      // 保存到本地存储
      if (this.isStored(itemId)) {
        const index = this.storedItems.indexOf(itemId);
        if (index !== -1) {
          this.storedItems.splice(index, 1);
          //取消收藏
          var useid = this.userid;
          var name = caseName;
          $.ajax({
            type: "get",
            url: "http://47.93.13.70:8081/collection/deletecase",
            contentType: "application/json;charset=utf-8",
            data: {
              useid,
              name,
            },
            success: (res) => {
              this.$message.error("已取消收藏！");
            },
            error: function (err) {
              alert(JSON.stringify(err));
            },
          });
        }
      } else {
        this.storedItems.push(itemId);
        //添加收藏
        var useid = this.userid;
        var name = caseName;
        console.log(name);
        $.ajax({
          type: "get",
          url: "http://47.93.13.70:8081/collection/addcase",
          contentType: "application/json;charset=utf-8",
          data: {
            useid,
            name,
          },
          success: (res) => {
            //alert("添加成功！");
            this.$message.success("收藏成功^_^");
          },
          error: function (err) {
            alert(JSON.stringify(err));
          },
        });
      }
      localStorage.setItem("storedItems", JSON.stringify(this.storedItems));
    },
    isStored(itemId) {
      return this.storedItems.includes(itemId);
    },
    loadFromLocalStorage() {
      this.likedItems = JSON.parse(localStorage.getItem("likedItems")) || [];
      this.storedItems = JSON.parse(localStorage.getItem("storedItems")) || [];
    },

    // 下载文件
    downloadFile() {
      if (this.caseInfo.file == "暂无") {
        this.$message.error("文件不存在");
      } else {
        console.log(this.caseInfo.file);
        // 下载文件
        // 这里可以使用axios或其他方法发送请求下载文件
        window.location.href = this.caseInfo.file;
        this.$message.success("文件下载成功");
      }
    },
    //预览文件
    preview() {
      if (this.caseInfo.file == "暂无") {
        this.$message.error("文件不存在");
      } else {
        console.log(this.caseInfo.file);
        // 下载文件
        // 这里可以使用axios或其他方法发送请求下载文件
        window.open(this.caseInfo.file);
        this.$message.success("文件下载成功");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.caseDetail {
  width: 1200px;
  height: 620px;
  margin: auto;
}

.caseDetailMain {
  width: 1100px;
  height: 200px;
  padding-top: 10px;
  border-radius: 10px;
  margin: auto;
}

.caseDetail-title {
  color: black;
  font-size: 20px;
  font-weight: bold;
  margin-left: 20px;
}

.caseDetail-author {
  color: black;
  font-size: 15px;
  margin-left: 20px;
  height: 35px;
  border-radius: 5px;
  background-color: rgb(240, 244, 244);
}

.author-img {
  float: left;
}

.author {
  float: left;
  height: 35px;
  margin-left: 5px;
  line-height: 35px;
  color: black;
  cursor: pointer;
  /* 设置光标样式为小手 */
}

.author:hover {
  text-decoration: underline;
}

.time {
  float: left;
  margin-left: 25px;
  line-height: 35px;
  font-size: 12px;
}

.view {
  float: left;
  margin-left: 25px;
  line-height: 35px;
  font-size: 12px;
}

.likes {
  float: left;
  margin-left: 25px;
  line-height: 35px;
  font-size: 12px;
}

.caseDescription {
  font-size: 14px;
  margin-top: 10px;
  margin-left: 20px;
}

.caseFile {
  margin-left: 20px;
  margin-top: 20px;
  height: 100px;
}

.downLoad {
  width: 120px;
  height: 30px;
  border-radius: 10px;
  text-align: center;
  line-height: 30px;
  background-color: rgb(246, 220, 186);
  color: black;
  margin-top: 20px;
  margin-left: 20px;
  cursor: pointer;
  /* 设置光标样式为小手 */
}

.downLoad:hover {
  background-color: rgb(250, 231, 208);
}

.score {
  margin-left: 20px;
  margin-top: 20px;
}

.like {
  margin-top: 10px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  font-size: 17px;
}

.likeImg {
  margin-right: 25px;
}

.icon-clicked {
  color: red;
}
</style>
