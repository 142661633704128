<template>
  <div class="homePage" style="height: 100%">
    <div class="main">
      <div class="header">
        <div class="title">
          <img src="logo1.png" alt="" width="40px" height="25px" />
          智能案例教学平台
        </div>
        <router-link to="/caseStudy" class="nav">案例教学</router-link>
        <router-link to="/video" class="nav">教学视频</router-link>
        <router-link to="/caseEx" class="nav">案例体验</router-link>
        <router-link to="/assistant" class="nav">智能助手</router-link>
        <router-link to="/myPost/myPostCase" class="nav">我的发布</router-link>
        <router-link to="/personalCenter" class="nav">个人中心</router-link>
        <el-popover placement="bottom" width="180" trigger="click">
          <div class="post">
            <div class="postCase">
              <router-link to="/postCase" class="postC">
                <img src="case.png" alt="" width="56px" height="56px" />
                <div class="postfont">发布案例</div>
              </router-link>
            </div>
            <div class="postVideo">
              <router-link to="/postVideo" class="postC">
                <img src="video.png" alt="" width="56px" height="56px" />
                <div class="postfont">发布视频</div>
              </router-link>
            </div>
          </div>
          <el-button slot="reference" icon="el-icon-circle-plus"
            >发布</el-button
          >
        </el-popover>
        <div class="user">
          <el-avatar :src="this.headImg">用户</el-avatar>
          <div class="username">
            <el-dropdown trigger="click">
              <span class="el-dropdown-link">
                {{ this.username
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <div @click="login">
                  <el-dropdown-item icon="el-icon-circle-close"
                    >退出</el-dropdown-item
                  >
                </div>
                <div @click="register">
                  <el-dropdown-item icon="el-icon-circle-plus-outline"
                    >注册</el-dropdown-item
                  >
                </div>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
      <div class="content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { color } from "echarts";
export default {
  data() {
    return {
      username: "",
      headImg: "",
      useId: "",
    };
  },
  mounted() {
    this.username = localStorage.getItem("username");
    this.headImg = localStorage.getItem("headImg");
    this.getInfo();
  },
  created() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      axios.get("http://47.93.13.70:8081/user/show").then((res) => {
        for (var i = 0; i <= res.data.length - 1; i++) {
          if (this.username == res.data[i].username) {
            this.headImg = res.data[i].avatar;
            this.useId = res.data[i].id;
            localStorage.setItem("headImg", res.data[i].avatar);
            localStorage.setItem("userid", res.data[i].id);
            break;
          }
        }
      });
    },
    //切换用户清除本地存储的点赞状态
    clearLocalStorage() {
      localStorage.removeItem("likedItems");
      localStorage.removeItem("storedItems");
      localStorage.removeItem("videoLikedItems");
      localStorage.removeItem("videoStoredItems");
    },
    login() {
      this.$router.push("/login");
      this.clearLocalStorage();
      localStorage.removeItem("username");
      localStorage.removeItem("userid");
      localStorage.removeItem("headImg");
    },
    register() {
      this.$router.push("/register");
      this.clearLocalStorage();
      localStorage.removeItem("username");
      localStorage.removeItem("userid");
      localStorage.removeItem("headImg");
    },
  },
};
</script>

<style lang="less" scoped>
.homePage {
  background-color: rgb(214, 229, 226);
  display: flex;
  align-items: center;
}

.main {
  width: 1400px;
  height: 670px;
  background-color: white;
  margin: auto;
  border-radius: 8px;
}

.header {
  width: 100%;
  height: 50px;
  line-height: 50px;
  background-color: black;
  border-radius: 10px;
}

.title {
  float: left;
  color: rgb(1, 175, 174);
  font-weight: 550;
  font-size: 18px;
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.nav {
  display: inline-block;
  width: 150px;
  height: 50px;
  border-radius: 8px;
  line-height: 50px;
  color: white;
  font-weight: 550;
  text-align: center;
}

.nav:hover {
  color: rgb(1, 175, 174);
}

.postCase {
  float: left;
  margin-right: 65px;
  text-align: center;
  color: black;
}

.postVideo {
  float: left;
  text-align: center;
  color: black;
}

.postfont {
  color: #000;
}

.postfont:hover {
  color: rgb(1, 175, 174);
}

.el-button {
  height: 32px;
  width: 65px;
  font-weight: 550;
  line-height: 32px;
  padding: 0;
  border-radius: 10px;
  margin-left: 15px;
  color: rgb(1, 175, 174);
}

.user {
  float: right;
  margin-right: 20px;
}

.el-avatar {
  margin-top: 8px;
  margin-right: 5px;
  width: 35px;
  height: 35px;
}

.username {
  float: right;
  cursor: pointer;
  /* 设置光标样式为小手 */
}

.el-dropdown-link {
  color: rgb(1, 175, 174);
  font-weight: 600;
}
</style>
