import Vue from 'vue'
import { Button } from 'element-ui'
import { Form, FormItem } from 'element-ui'
import { Input } from 'element-ui'
import { Message } from 'element-ui'
import { Container } from 'element-ui'
import { Header } from 'element-ui'
import { Footer } from 'element-ui'
import { Main } from 'element-ui'
import { Menu } from 'element-ui'
import { Submenu } from 'element-ui'
import { MenuItemGroup } from 'element-ui'
import { MenuItem } from 'element-ui'
import { Carousel } from 'element-ui'
import { CarouselItem } from 'element-ui'
import { Aside } from 'element-ui'
import { Autocomplete } from 'element-ui'
import { TabPane } from 'element-ui'
import { Tabs } from 'element-ui'
import { Checkbox } from 'element-ui'
import { Select } from 'element-ui'
import { Option } from 'element-ui'
import { Breadcrumb } from 'element-ui'
import { BreadcrumbItem } from 'element-ui'
import { PageHeader } from 'element-ui'
import { TimePicker } from 'element-ui'
import { Col } from 'element-ui'
import { Switch } from 'element-ui'
import { Radio } from 'element-ui'
import { RadioGroup } from 'element-ui'
import { DatePicker } from 'element-ui'
import { Table } from 'element-ui'
import { TableColumn } from 'element-ui'
import { Dropdown } from 'element-ui'
import { DropdownItem } from 'element-ui'
import { DropdownMenu } from 'element-ui'
import { Pagination } from 'element-ui'
import { Dialog } from 'element-ui'
import { Card } from 'element-ui'
import { Divider } from 'element-ui'
import { Tooltip } from 'element-ui'
import { Link } from 'element-ui'
import { Popover } from 'element-ui'
import { Avatar } from 'element-ui'
import { Descriptions } from 'element-ui'
import { DescriptionsItem } from 'element-ui'
import { Upload } from 'element-ui'
import { Tag } from 'element-ui'
import { Rate } from 'element-ui'
import { InfiniteScroll } from 'element-ui'
import { Empty } from 'element-ui'

Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Container)
Vue.use(Header)
Vue.use(Main)
Vue.use(Aside)
Vue.use(Footer)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Autocomplete)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Checkbox)
Vue.use(Select)
Vue.use(Option)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(PageHeader)
Vue.use(TimePicker)
Vue.use(Col)
Vue.use(Switch)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(DatePicker)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Dropdown)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(Card)
Vue.use(Divider)
Vue.use(Tooltip)
Vue.use(Link)
Vue.use(Popover)
Vue.use(Avatar)
Vue.use(Descriptions)
Vue.use(DescriptionsItem)
Vue.use(Upload)
Vue.use(Tag)
Vue.use(Rate)
Vue.use(InfiniteScroll)
Vue.use(Empty)
Vue.prototype.$message = Message


