<template>
  <div class="post">
    <div class="postVideo">
      <div class="postVideo">
        <div class="title">
          <img src="fabu.png" alt="" width="40px" height="40px" />发 布 视 频
        </div>
        <el-form
          :model="ruleForm"
          status-icon
          :rules="rules"
          ref="ruleFormA"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="视频名称" prop="name">
            <el-input
              v-model="ruleForm.name"
              autocomplete="off"
              maxlength="10"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="视频简介" prop="description">
            <el-input
              type="textarea"
              v-model="ruleForm.description"
              maxlength="35"
              show-word-limit
              :autosize="{ minRows: 2, maxRows: 2 }"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="案例视频" prop="videofile">
            <el-upload
              class="upload-demo"
              drag
              action="#"
              :file-list="videoList"
              ref="upload"
              :auto-upload="false"
              :limit="1"
              :on-exceed="handleExceed"
              :on-change="handleChange"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将视频拖到此处，或<em>点击上传</em>
              </div>
              <div @click="videoRemove(file)"></div>
              <div class="el-upload__tip" slot="tip">
                只能上传MP4格式视频，且不超过300Mb
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item>
            <el-button type="success" @click="submitForm">发布</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import * as echarts from "echarts";
import $ from "jquery";
export default {
  data() {
    return {
      author: "",
      videoList: [],
      ruleForm: {
        name: "",
        description: "",
        videofile: "",
      },
      rules: {
        name: [
          { required: true, message: "视频名称不能为空！", trigger: "blur" },
        ],

        description: [
          { required: true, message: "视频简介不能为空！", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.author = localStorage.getItem("username");
  },
  methods: {
    submitForm(fail) {
      this.$refs.ruleFormA.validate(async (valid) => {
        if (!valid) {
          return;
        }
        if (this.ruleForm.videofile == "") {
          this.$message.error("请上视频文件！");
          return;
        }
        var formData = new FormData();
        formData.append("name", this.ruleForm.name);
        formData.append("introduction", this.ruleForm.description);
        formData.append("video", this.ruleForm.videofile);
        formData.append("username", this.author);
        console.log(formData.get("video"));
        console.log(formData.get("name"));
        console.log(formData.get("introduction"));
        console.log(formData.get("username"));
        $.ajax({
          type: "post",
          url: "http://47.93.13.70:8081/video/add",
          contentType: false, // jQuery不要去设置Content-Type请求头
          processData: false, // jQuery不要去处理发送的数据
          async: false,
          data: formData,
          success: (res) => {
            console.log(res + "1");
            this.$message.success("文件上传成功，等待管理员审核！");
          },
          error: (err) => {
            this.$message.error("视频发布失败！");
          },
        });
      });
      // 发送POST请求将数据提交到后端接口
    },
    //删除视频方法
    videoRemove(file) {
      const index = this.videoList.indexOf(file);
      console.log(index);
      if (index !== -2) {
        this.videoList.splice(index + 1, 1);
      }
    },
    //文件限制
    handleExceed(files, fileList) {
      this.$message.warning("当前限制选择 1个文件,请删除后继续上传！");
    },
    //视频提交方法
    handleChange(file, fileList) {
      this.ruleForm.videofile = file.raw;
      if (file.raw.type != "video/mp4") {
        this.$message.error("只能上传MP4文件！");
        this.videoRemove(file);
        return;
      }
      if (file.raw.size > 300 * 1024 * 1024) {
        this.$message.error("文件大小不能超过300Mb！");
        this.videoRemove(file);
        return;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.post {
  width: 100%;
  height: 100%;
}

.title {
  font-size: 23px;
  font-weight: 550;
  padding-top: 15px;
  margin-bottom: 25px;
  margin-left: 290px;
  display: flex;
  align-items: center;
  color: rgb(24, 122, 122);
}

.title img {
  margin-right: 5px;
}

.postVideo {
  width: 750px;
  height: 560px;
  border-radius: 15px;
  box-shadow: 0 0 10px 3px rgb(247, 244, 240);
  margin: auto;
  margin-top: 30px;
  background-color: rgb(251, 245, 236);
}

.demo-ruleForm {
  width: 500px;
  margin: auto;
}
</style>
