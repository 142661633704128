<template>
  <div class="caseStudy">
    <div class="search">
      <el-input
        class="input"
        v-model="input"
        placeholder="请输入案例"
        prefix-icon="el-icon-search"
      ></el-input>
      <div class="sea" @click="search">搜索</div>
    </div>
    <div class="case">
      <el-col :span="24">
        <el-card shadow="hover" v-for="(item, index) in cardItems" :key="index">
          <div @click="toCaseDetail(item.id, item.caseName)">
            <div class="left">
              <div class="author">
                <img :src="item.headImg" alt="" width="30px" height="30px" />
                <div class="authorName" @click="toUser(item.author)">
                  {{ item.author }}
                </div>
                <div class="follow" @click.stop="follow(item.author)">关注</div>
                <div class="time">{{ item.time }}</div>
              </div>
              <div class="content">
                <h4 class="caseName">{{ item.caseName }}</h4>
                <p class="text">
                  {{ item.caseDescription }}
                </p>
              </div>
              <div class="like">
                <div
                  @click.stop="toggleLike(item.id, item.caseName)"
                  class="likeImg"
                  :class="{ 'icon-clicked': isLiked(item.id) }"
                >
                  <i class="el-icon-thumb"></i>
                </div>
                <div
                  @click.stop="store(item.id, item.caseName)"
                  class="likeImg"
                  :class="{ 'icon-clicked': isStored(item.id) }"
                >
                  <i class="el-icon-star-off"></i>
                </div>
              </div>
            </div>
            <div class="right">
              <img :src="item.fenImg" alt="" width="140px" height="130px" />
            </div>
          </div>
        </el-card>
      </el-col>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import * as echarts from "echarts";
import { Button } from "element-ui";
import $ from "jquery";
export default {
  props: ["item"],
  data() {
    return {
      input: "",
      value1: "",
      isLike: "false",
      isStore: "false",
      userid: "", //用户名
      likedItems: [],
      storedItems: [],
      cardItems: [],
    };
  },

  mounted() {
    this.userid = localStorage.getItem("userid");
    this.loadFromLocalStorage();
    this.getCardItems();
  },
  computed: {
    filteredItems() {
      return this.cardItems.filter((item) => {
        return item.caseName.toLowerCase().includes(this.input.toLowerCase());
      });
    },
  },
  methods: {
    //获取用户头像
    async getInfo(username) {
      try {
        const res = await axios.get("http://47.93.13.70:8081/user/show");
        for (var i = 0; i <= res.data.length - 1; i++) {
          if (username == res.data[i].username) {
            return res.data[i].avatar;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    //得到数据
    async getCardItems() {
      try {
        const res = await $.ajax({
          type: "get",
          url: "http://47.93.13.70:8081/case/show",
          contentType: "application/json;charset=utf-8",
          dataType: "json",
        });
        this.cardItems = [];
        for (var i = 0; i < res.length; i++) {
          if (res[i].state === "发布中") {
            // 添加条件判断
            const headImg = await this.getInfo(res[i].publisher);
            this.cardItems.push({
              id: res[i].id,
              headImg: headImg,
              author: res[i].publisher,
              caseName: res[i].name,
              time: res[i].casetime,
              caseDescription: res[i].introduction,
              fenImg: res[i].cover,
            });
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 搜索方法
    async search() {
      var name = this.input;
      try {
        const res = await $.ajax({
          type: "get",
          url: "http://47.93.13.70:8081/case/searchone",
          contentType: "application/json;charset=utf-8",
          dataType: "json",
          data: { name },
        });
        // 直接使用响应数据，无需再进行字符串化和解析
        this.cardItems = [];
        for (let i = 0; i < res.length; i++) {
          if (res[i].state === "发布中") {
            // 添加条件判断
            const headImg = await this.getInfo(res[i].publisher);
            this.cardItems.push({
              id: res[i].id,
              headImg: headImg,
              author: res[i].publisher,
              caseName: res[i].name,
              time: res[i].casetime,
              caseDescription: res[i].introduction,
              fenImg: res[i].cover,
            });
          }
        }
        if (this.cardItems.length === 0) {
          this.$message.error("该案例不存在哦！");
          return;
        }
      } catch (error) {
        alert(JSON.stringify(error));
      }
    },
    //跳转到案例详情
    toCaseDetail(id, name) {
      // 将数据作为查询字符串参数传递
      this.$router.push({
        path: "/caseDetail",
        query: { id: id },
      });
      console.log(name);
      $.ajax({
        type: "get",
        url: "http://47.93.13.70:8081/case/liulan",
        contentType: "application/json;charset=utf-8",
        data: {
          name,
        },
        success: (res) => {},
        error: function (err) {
          alert(JSON.stringify(err));
        },
      });
    },

    //跳转到个人详情
    toUser(author) {
      // 将数据作为查询字符串参数传递
      this.$router.push({
        path: "/personalDetail",
        query: { author: author },
      });
    },
    //关注方法
    follow(author) {
      var useid = this.userid;
      var username = author;
      this.$message.success("已关注！");
      // 调用关注接口
      // $.ajax({
      //     type: "get",
      //     url: "http://localhost:8081/dianzan/deletecase",
      //     contentType: "application/json;charset=utf-8",
      //     data: {
      //         useid,
      //         username,
      //     },
      //     success: (res) => {
      //         this.$message.success("已关注！");
      //     },
      //     error: function (err) {
      //         alert(JSON.stringify(err));
      //     },
      // });
    },

    //点赞方法
    toggleLike(itemId, caseName) {
      // 检查当前项是否已经被点赞
      if (this.isLiked(itemId)) {
        const index = this.likedItems.indexOf(itemId);
        if (index !== -1) {
          this.likedItems.splice(index, 1);
          var useid = this.userid;
          var name = caseName;
          $.ajax({
            type: "get",
            url: "http://47.93.13.70:8081/dianzan/deletecase",
            contentType: "application/json;charset=utf-8",
            data: {
              useid,
              name,
            },
            success: (res) => {
              this.$message.error("已取消点赞！");
            },
            error: function (err) {
              alert(JSON.stringify(err));
            },
          });
        }
      } else {
        this.likedItems.push(itemId);
        //添加赞数
        var useid = this.userid;
        var name = caseName;
        console.log(name);
        $.ajax({
          type: "get",
          url: "http://47.93.13.70:8081/dianzan/addcase",
          contentType: "application/json;charset=utf-8",
          data: {
            useid,
            name,
          },
          success: (res) => {
            //alert("添加成功！");
            this.$message.success("作者谢谢你的支持^_^");
          },
          error: function (err) {
            alert(JSON.stringify(err));
          },
        });
      }
      localStorage.setItem("likedItems", JSON.stringify(this.likedItems));
    },
    isLiked(itemId) {
      return this.likedItems.includes(itemId);
    },

    isLiked(itemId) {
      return this.likedItems.includes(itemId);
    },

    //收藏方法
    store(itemId, caseName) {
      // 执行收藏的操作
      // 保存到本地存储
      if (this.isStored(itemId)) {
        const index = this.storedItems.indexOf(itemId);
        if (index !== -1) {
          this.storedItems.splice(index, 1);
          //取消收藏
          var useid = this.userid;
          var name = caseName;
          $.ajax({
            type: "get",
            url: "http://47.93.13.70:8081/collection/deletecase",
            contentType: "application/json;charset=utf-8",
            data: {
              useid,
              name,
            },
            success: (res) => {
              this.$message.error("已取消收藏！");
            },
            error: function (err) {
              alert(JSON.stringify(err));
            },
          });
        }
      } else {
        this.storedItems.push(itemId);
        //添加收藏
        var useid = this.userid;
        var name = caseName;
        console.log(name);
        $.ajax({
          type: "get",
          url: "http://47.93.13.70:8081/collection/addcase",
          contentType: "application/json;charset=utf-8",
          data: {
            useid,
            name,
          },
          success: (res) => {
            //alert("添加成功！");
            this.$message.success("收藏成功^_^");
          },
          error: function (err) {
            alert(JSON.stringify(err));
          },
        });
      }
      localStorage.setItem("storedItems", JSON.stringify(this.storedItems));
    },
    isStored(itemId) {
      return this.storedItems.includes(itemId);
    },
    loadFromLocalStorage() {
      this.likedItems = JSON.parse(localStorage.getItem("likedItems")) || [];
      this.storedItems = JSON.parse(localStorage.getItem("storedItems")) || [];
    },
  },
};
</script>

<style lang="less" scoped>
.search {
  width: 1100px;
  height: 50px;
  margin-top: 40px;
  margin-left: 360px;
  margin-bottom: 20px;
}

.input {
  display: block;
  width: 600px;
  height: 50px;
  float: left;
}

.sea {
  width: 80px;
  height: 45px;
  float: left;
  text-align: center;
  line-height: 45px;
  font-size: 16px;
  color: white;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  background-color: rgb(1, 175, 174);
  cursor: pointer;
  /* 设置光标样式为小手 */
}

/deep/.el-input__inner {
  height: 45px;
  line-height: 45px;
  border: 2px solid rgb(1, 175, 174);
  border-radius: 0;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
}

/deep/.el-input__inner:focus {
  border-color: rgb(1, 175, 174);
}

.case {
  width: 1150px;
  height: 500px;
  margin: auto;
  /* 设置容器的固定高度 */
  overflow: auto;
  /* 启用滚动条 */
}

/deep/.el-card__body {
  padding: 8px;
}

.el-card:hover {
  /* 边框颜色变化 */
  background-color: #f6faf1;
  /* 背景色变化 */
}

.left {
  width: 950px;
  height: 100%;
  float: left;
}

.right {
  width: 130px;
  height: 140px;
  float: left;
}

.author {
  width: 940px;
  display: flex;
  align-items: center;
  line-height: 30px;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.authorName {
  color: black;
  margin-left: 5px;
  cursor: pointer;
  /* 设置光标样式为小手 */
}

.authorName:hover {
  text-decoration: underline;
}

.caseName {
  color: black;
  cursor: pointer;
  /* 设置光标样式为小手 */
}

.caseName:hover {
  text-decoration: underline;
}

.follow {
  width: 40px;
  height: 20px;
  line-height: 20px;
  background-color: sandybrown;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  margin-left: 10px;
  cursor: pointer;
  /* 设置光标样式为小手 */
}

.time {
  margin-left: 20px;
  font-size: 12px;
  margin-top: 2px;
  font-weight: 400;
  float: right;
}

.content {
  width: 930px;
  height: 65px;
  margin-left: 5px;
}

.text {
  display: block;
  margin-top: 5px;
  font-size: 14px;
  height: 20px;
}

.like {
  margin-top: 3px;
  display: flex;
  align-items: center;
  font-size: 17px;
}

.likeImg {
  margin-right: 15px;
  margin-left: 10px;
}

.icon-clicked {
  color: red;
}
</style>
