<template>
  <div class="post">
    <div class="postCase">
      <div class="title">
        <img src="fabu.png" alt="" width="40px" height="40px" />发 布 案 例
      </div>
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleFormRef"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="案例名称" prop="name">
          <el-input
            v-model="ruleForm.name"
            autocomplete="off"
            maxlength="20"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="案例简介" prop="description">
          <el-input
            type="textarea"
            autosize
            v-model="ruleForm.description"
            maxlength="130"
            show-word-limit
            :autosize="{ minRows: 2, maxRows: 2 }"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="案例封面" prop="cover">
          <el-upload
            action="#"
            list-type="picture-card"
            :on-exceed="chandleExceed"
            :auto-upload="false"
            :limit="1"
            :file-list="imgList"
            :on-change="imgHandleChange"
          >
            <i slot="default" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{ file }">
              <img
                class="el-upload-list__item-thumbnail"
                :src="file.url"
                alt=""
              />
              <span class="el-upload-list__item-actions">
                <span
                  class="el-upload-list__item-preview"
                  @click="handlePictureCardPreview(file)"
                >
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span
                  v-if="!disabled"
                  class="el-upload-list__item-delete"
                  @click="handleRemove(file)"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </el-form-item>
        <el-form-item label="案例文档" prop="casefile">
          <el-upload
            class="upload-demo"
            ref="upload"
            action="#"
            :on-exceed="handleExceed"
            :on-change="handleChange"
            :file-list="fileList"
            :auto-upload="false"
            :limit="1"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div @click="caseRemove(file)"></div>
            <div slot="tip" class="el-upload__tip">
              只能上传pdf文件，且不超过100M
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button type="success" @click="submitForm">发布</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
export default {
  data() {
    return {
      dialogVisible: false,
      dialogImageUrl: "",
      author: "",
      disabled: false,
      fileList: [],

      imgList: [],
      formName: "ruleForm",
      ruleForm: {
        name: "",
        description: "",
        cover: "",
        casefile: "",
      },

      rules: {
        name: [
          { required: true, message: "案例名称不能为空！", trigger: "blur" },
        ],

        description: [
          { required: true, message: "案例简介不能为空！", trigger: "blur" },
        ],
      },
    };
  },

  mounted() {
    this.author = localStorage.getItem("username");
  },
  methods: {
    //文件限制
    chandleExceed(files, fileList) {
      this.$message.warning("当前限制选择 1个封面图片,请删除后继续上传！");
    },
    //文件限制
    handleExceed(files, fileList) {
      this.$message.warning("当前限制选择 1个文件,请删除后继续上传！");
    },
    submitForm(formName) {
      this.$refs.ruleFormRef.validate(async (valid) => {
        if (!valid) {
          return;
        }
        if (this.ruleForm.cover == "") {
          this.$message.error("请上传封面图片！");
          return;
        }
        if (this.ruleForm.casefile == "") {
          this.$message.error("请上传案例文档！");
          return;
        }
        // 验证通过，提交表单
        // 这里可以调用后端接口，将表单数据提交到后端
        // 例如：
        var formData = new FormData();
        formData.append("name", this.ruleForm.name);
        formData.append("introduction", this.ruleForm.description);
        formData.append("username", this.author);
        formData.append("fileImage", this.ruleForm.cover);
        formData.append("anli", this.ruleForm.casefile);
        console.log(formData.get("fileImage"));
        // 提交表单
        // 这里可以调用后端接口，将表单数据提交到后端
        // 例如：
        $.ajax({
          type: "post",
          url: "http://47.93.13.70:8081/case/add",
          contentType: false, // jQuery不要去设置Content-Type请求头
          processData: false, // jQuery不要去处理发送的数据
          data: formData,
          success: (res) => {
            this.$message.success("文件上传成功，等待管理员审核！");
          },
          error: (err) => {
            this.$message.error("文件上传失败，请检查！");
          },
        });
      });
    },

    //文件方法
    handleChange(file, fileList) {
      this.ruleForm.casefile = file.raw;
      //验证是不是pdf文件，且大小不超过100M
      if (file.raw.type != "application/pdf") {
        this.$message.error("只能上传PDF文件！");
        this.caseRemove(file);
        return;
      }
      if (file.raw.size > 100 * 1024 * 1024) {
        this.$message.error("文件大小不能超过100M！");
        this.caseRemove(file);
        return;
      }
      return true;
      console.log(file.raw.size);
    },
    imgHandleChange(file, imgList) {
      this.ruleForm.cover = file.raw;

      //文件类型不为图片格式进行提示
      if (
        file.raw.type != "image/jpeg" &&
        file.raw.type != "image/png" &&
        file.raw.type != "image/gif"
      ) {
        this.$message.error("只能上传图片文件！");
        this.handleRemove(file);
        return;
      }
      //文件大小不超过100M
      if (file.raw.size > 10 * 1024 * 1024) {
        this.$message.error("文件大小不能超过10M！");
        this.handleRemove(file);
        return;
      }
    },

    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    //删除图片方法
    handleRemove(file) {
      const index = this.imgList.indexOf(file);
      console.log(index);
      if (index !== -2) {
        this.imgList.splice(index + 1, 1);
      }
    },

    //删除案例文件方法
    caseRemove(file) {
      const index = this.fileList.indexOf(file);
      console.log(index);
      if (index !== -2) {
        this.fileList.splice(index + 1, 1);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.post {
  width: 100%;
  height: 100%;
}

.title {
  font-size: 23px;
  font-weight: 550;
  padding-top: 15px;
  margin-bottom: 25px;
  margin-left: 290px;
  display: flex;
  align-items: center;
  color: rgb(24, 122, 122);
}

.title img {
  margin-right: 5px;
}

.postCase {
  width: 750px;
  height: 560px;
  border-radius: 15px;
  box-shadow: 0 0 10px 3px rgb(247, 244, 240);
  margin: auto;
  margin-top: 30px;
  background-color: rgb(251, 245, 236);
}

.demo-ruleForm {
  width: 500px;
  margin: auto;
}

/deep/.el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: 100px;
}

/deep/.el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
}
</style>
