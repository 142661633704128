<template>
  <div class="myPostVideo">
    <div class="search">
      <div style="margin-bottom: 10px">
        <el-input
          placeholder="请输入视频编号"
          size="small"
          v-model="inputId"
          style="width: 150px; margin-right: 10px"
          clearable
        >
        </el-input>
        <el-input
          placeholder="请输入视频名称"
          size="small"
          v-model="inputName"
          style="width: 150px; margin-right: 10px"
          clearable
        >
        </el-input>
        <el-button
          type="primary"
          size="small"
          icon="el-icon-search"
          @click="search"
          >搜索</el-button
        >
      </div>
    </div>

    <el-tabs v-model="activeName" @tab-click="handleClick" type="card">
      <el-tab-pane label="全部" name="first">
        <div class="concent">
          <el-table
            ref="filterTable"
            :data="tableData.slice(startIndex, endIndex)"
            style="width: 100%"
          >
            <el-table-column
              prop="id"
              label="视频编号"
              width="80"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="name"
              label="视频名称"
              width="130"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="description"
              label="视频简介"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="time"
              label="发布时间"
              width="100px"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="video" label="视频文件" align="center">
              <template slot-scope="scope">
                <video
                  :src="scope.row.video"
                  controls="controls"
                  width="150px"
                  height="80px"
                ></video>
              </template>
            </el-table-column>
            <el-table-column
              prop="likes"
              label="获赞量"
              width="80px"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="tag"
              label="状态"
              width="100"
              :filters="[
                { text: '待审核', value: '待审核' },
                { text: '已下架', value: '已下架' },
                { text: '发布中', value: '发布中' },
              ]"
              :filter-method="filterTag"
              filter-placement="bottom-end"
              align="center"
            >
              <template slot-scope="scope">
                <el-tag :type="getTagType(scope.row.tag)" disable-transitions>{{
                  scope.row.tag
                }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  @click="handleEdit(scope.$index, scope.row)"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  type="warning"
                  @click="handleOff(scope.$index, scope.row)"
                  >下架</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.$index, scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :page-size="pageSize"
            :total="this.tableData.length"
            :current-page.sync="currentPage"
            @current-change="handlePageChange"
            layout="prev, pager, next"
          >
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="发布中" name="second">
        <div class="concent">
          <el-table
            ref="filterTable"
            :data="publishedData.slice(startIndexP, endIndexP)"
            style="width: 100%"
          >
            <el-table-column
              prop="id"
              label="视频编号"
              width="80"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="name"
              label="视频名称"
              width="130"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="description"
              label="视频简介"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="time"
              label="发布时间"
              width="100px"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="video" label="视频文件" align="center">
              <template slot-scope="scope">
                <video
                  :src="scope.row.video"
                  controls="controls"
                  width="150px"
                  height="80px"
                ></video>
              </template>
            </el-table-column>
            <el-table-column
              prop="likes"
              label="获赞量"
              width="80px"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="tag"
              label="状态"
              width="100"
              :filters="[
                { text: '待审核', value: '待审核' },
                { text: '已下架', value: '已下架' },
                { text: '发布中', value: '发布中' },
              ]"
              :filter-method="filterTag"
              filter-placement="bottom-end"
              align="center"
            >
              <template slot-scope="scope">
                <el-tag :type="getTagType(scope.row.tag)" disable-transitions>{{
                  scope.row.tag
                }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  @click="handleEdit(scope.$index, scope.row)"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  type="warning"
                  @click="handleOff(scope.$index, scope.row)"
                  >下架</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.$index, scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :page-size="pageSize"
            :total="this.publishedData.length"
            :current-page.sync="currentPageP"
            @current-change="handlePageChangeP"
            layout="prev, pager, next"
          >
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="待审核" name="third">
        <div class="concent">
          <el-table
            ref="filterTable"
            :data="reviewData.slice(startIndexR, endIndexR)"
            style="width: 100%"
          >
            <el-table-column
              prop="id"
              label="视频编号"
              width="80"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="name"
              label="视频名称"
              width="130"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="description"
              label="视频简介"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="time"
              label="发布时间"
              width="100px"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="video" label="视频文件" align="center">
              <template slot-scope="scope">
                <video
                  :src="scope.row.video"
                  controls="controls"
                  width="150px"
                  height="80px"
                ></video>
              </template>
            </el-table-column>
            <el-table-column
              prop="likes"
              label="获赞量"
              width="80px"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="tag"
              label="状态"
              width="100"
              :filters="[
                { text: '待审核', value: '待审核' },
                { text: '已下架', value: '已下架' },
                { text: '发布中', value: '发布中' },
              ]"
              :filter-method="filterTag"
              filter-placement="bottom-end"
              align="center"
            >
              <template slot-scope="scope">
                <el-tag :type="getTagType(scope.row.tag)" disable-transitions>{{
                  scope.row.tag
                }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  @click="handleEdit(scope.$index, scope.row)"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.$index, scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :page-size="pageSize"
            :total="this.reviewData.length"
            :current-page.sync="currentPageR"
            @current-change="handlePageChangeR"
            layout="prev, pager, next"
          >
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="已下架" name="fourth">
        <div class="concent">
          <el-table
            ref="filterTable"
            :data="offlineData.slice(startIndexO, endIndexO)"
            style="width: 100%"
          >
            <el-table-column
              prop="id"
              label="视频编号"
              width="80"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="name"
              label="视频名称"
              width="130"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="description"
              label="视频简介"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="time"
              label="发布时间"
              width="100px"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="video"
              label="视频文件"
              align="center"
              width="200px"
            >
              <template slot-scope="scope">
                <video
                  :src="scope.row.video"
                  controls="controls"
                  width="150px"
                  height="80px"
                ></video>
              </template>
            </el-table-column>
            <el-table-column
              prop="likes"
              label="获赞量"
              width="80px"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="tag"
              label="状态"
              width="100"
              :filters="[
                { text: '待审核', value: '待审核' },
                { text: '已下架', value: '已下架' },
                { text: '发布中', value: '发布中' },
              ]"
              :filter-method="filterTag"
              filter-placement="bottom-end"
              align="center"
            >
              <template slot-scope="scope">
                <el-tag :type="getTagType(scope.row.tag)" disable-transitions>{{
                  scope.row.tag
                }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  @click="handleEdit(scope.$index, scope.row)"
                  >编辑</el-button
                >

                <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.$index, scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :page-size="pageSize"
            :total="this.offlineData.length"
            :current-page.sync="currentPageO"
            @current-change="handlePageChangeO"
            layout="prev, pager, next"
          >
          </el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>

    <div class="opDialog">
      <el-dialog title="视频信息修改" :visible.sync="dialogFormVisible">
        <el-form :model="eidtVideoInfo">
          <el-form-item label="视频名称：" :label-width="formLabelWidth">
            <el-input
              v-model="eidtVideoInfo.name"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="视频简介：" :label-width="formLabelWidth">
            <el-input
              v-model="eidtVideoInfo.description"
              autocomplete="off"
              type="textarea"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="eidtVideoInfo1">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import * as echarts from "echarts";
import $ from "jquery";
export default {
  data() {
    return {
      inputId: "",
      inputName: "",
      activeName: "first",
      publishedData: [], // 存放发布中的数据
      reviewData: [], // 存放审核中的数据
      offlineData: [], // 存放已下架的数据
      //表单数据
      dialogFormVisible: false,
      //个人信息数据
      //编辑表单数据
      eidtVideoInfo: {
        name: "",
        description: "",
        tag: "",
        id: null,
      },
      formLabelWidth: "100px", //表单域的宽度
      //分页
      currentPage: 1, // 全部当前页码
      currentPageP: 1, // 已发布当前页码
      currentPageR: 1, // 审核中当前页码
      currentPageO: 1, // 已下架当前页码
      pageSize: 3, // 每页显示的行数
      tableData: [{}],
    };
  },
  computed: {
    //全部分页功能
    startIndex() {
      // 计算起始位置
      return (this.currentPage - 1) * this.pageSize;
    },
    //已发布分页功能
    startIndexP() {
      // 计算起始位置
      return (this.currentPageP - 1) * this.pageSize;
    },
    //待审核分页功能
    startIndexR() {
      // 计算起始位置
      return (this.currentPageR - 1) * this.pageSize;
    },
    //已下架分页功能
    startIndexO() {
      // 计算起始位置
      return (this.currentPageO - 1) * this.pageSize;
    },
    endIndex() {
      // 计算全部结束位置
      return this.startIndex + this.pageSize;
    },
    endIndexP() {
      // 计算全部结束位置
      return this.startIndexP + this.pageSize;
    },
    endIndexR() {
      // 计算全部结束位置
      return this.startIndexR + this.pageSize;
    },
    endIndexO() {
      // 计算全部结束位置
      return this.startIndexO + this.pageSize;
    },
  },
  mounted() {
    this.getTagType();
    this.getTableData();
  },
  created() {},
  methods: {
    handleClick(tab, event) {},
    formatter(row, column) {
      return row.address;
    },
    getOthereData() {
      // 在这里过滤出发布中的数据并赋值给 publishedData
      this.publishedData = this.tableData.filter(
        (item) => item.tag === "发布中"
      );
      // 在这里过滤出审核中的数据并赋值给 reviewData
      this.reviewData = this.tableData.filter((item) => item.tag === "待审核");
      // 在这里过滤出已下架的数据并赋值给 offlineData
      this.offlineData = this.tableData.filter((item) => item.tag === "已下架");
    },
    filterHandler(value, row, column) {
      const property = column["property"];
      return row[property] === value;
    },

    //状态筛选
    filterTag(value, row) {
      return row.tag === value;
    },
    //设置状态颜色
    getTagType(status) {
      if (status === "待审核") {
        return "warning"; // 或者其他合适的样式类型
      } else if (status === "发布中") {
        return "success"; // 或者其他合适的样式类型
      } else if (status === "已下架") {
        return "danger"; // 或者其他合适的样式类型
      } else {
        return "info"; // 其他状态的默认样式类型
      }
    },
    handlePageChange(page) {
      // 处理分页器页码变化事件
      this.currentPage = page;
    },
    handlePageChangeP(page) {
      // 处理分页器页码变化事件
      this.currentPageP = page;
    },
    handlePageChangeR(page) {
      // 处理分页器页码变化事件
      this.currentPageR = page;
    },
    handlePageChangeO(page) {
      // 处理分页器页码变化事件
      this.currentPageO = page;
    },

    //搜索方法
    search() {
      var id = this.inputId;
      var name = this.inputName;
      $.ajax({
        type: "get",
        url: "http://47.93.13.70:8081/video/searchtwo",
        contentType: "application/json;charset=utf-8",
        dataType: "json",
        data: {
          id,
          name,
        },
        success: (res) => {
          const username = localStorage.getItem("username");
          this.tableData = res
            .filter((item) => item.publisher === username)
            .map((item) => ({
              id: item.id,
              name: item.name,
              description: item.introduction,
              time: item.time,
              video: item.video,
              likes: item.likes,
              tag: item.state,
            }));
          if (this.tableData.length === 0) {
            this.$message.error("你还未发布该案例哦！");
            return;
          }
        },
        error: (err) => {
          this.$message.error("输入不合法！");
        },
      });
    },
    //获取表单数据
    getTableData() {
      $.ajax({
        type: "get",
        url: "http://47.93.13.70:8081/video/show",
        contentType: "application/json;charset=utf-8",
        dataType: "json",
        success: (res) => {
          const username = localStorage.getItem("username");
          this.tableData = res
            .filter((item) => item.publisher === username)
            .map((item) => ({
              id: item.id,
              name: item.name,
              description: item.introduction,
              time: item.time,
              video: item.video,
              likes: item.likes,
              tag: item.state,
            }));
          this.getOthereData();
        },
        error: (res) => {
          this.$message.error("获取数据失败，请检查！");
        },
      });
    },
    //编辑
    handleEdit(index, row) {
      this.eidtVideoInfo.id = row.id;
      this.eidtVideoInfo.name = row.name;
      this.eidtVideoInfo.description = row.description;
      //var state = row.tag;
      this.dialogFormVisible = "true";

      var name = this.eidtVideoInfo.name;
      var introduction = this.eidtVideoInfo.description;
    },
    eidtVideoInfo1() {
      var id = this.eidtVideoInfo.id;
      //this.dialogFormVisible = 'true';
      var name = this.eidtVideoInfo.name;
      var introduction = this.eidtVideoInfo.description;
      var state = "待审核";

      $.ajax({
        type: "get",
        url: "http://47.93.13.70:8081/video/update",
        contentType: "application/json;charset=utf-8",
        dataType: "text",
        data: {
          id,
          name,
          introduction,
          state,
        },
        success: (res) => {
          this.$message.success("修改成功！");
          this.getTableData();
        },
        error: function (err) {
          alert(JSON.stringify(err));
        },
      });
      // 关闭弹窗
      this.dialogFormVisible = false;
    },
    //下架
    handleOff(index, row) {
      var id = row.id;
      var name = row.name;
      var introduction = row.dscription;
      var ostate = row.tag;
      var state = "已下架";
      //console.log(ostate)
      if (ostate == "已下架") {
        this.$message.success("该视频已下架，无需重复操作！");
      } else {
        if (confirm("确认下架？")) {
          $.ajax({
            type: "get",
            url: "http://47.93.13.70:8081/video/update",
            contentType: "application/json;charset=utf-8",
            dataType: "text",
            data: {
              id,
              name,
              introduction,
              state,
            },
            success: (res) => {
              this.$message.success("成功下架");
              this.getTableData();
            },
            error: function (err) {
              alert(JSON.stringify(err));
            },
          });
        } else {
          this.$message.info("下架操作已取消！");
        }
      }
    },

    //删除
    handleDelete(index, row) {
      var id = row.id;
      // 弹出确认删除的对话框
      if (confirm("确认要删除这个视频？")) {
        $.ajax({
          type: "get",
          url: "http://47.93.13.70:8081/video/delete",
          contentType: "application/json;charset=utf-8",
          data: { id },
          success: (res) => {
            this.$message.success("删除成功！");
            this.getTableData();
          },
          error: function (err) {
            alert(JSON.stringify(err));
          },
        });
      } else {
        this.$message.info("删除操作已取消！");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.search {
  margin-top: 10px;
}

.concent {
  width: 100%;
  margin: auto;
  height: 470px;
}

/deep/.el-tabs__item.is-active {
  color: rgb(1, 175, 174);
}

/deep/.el-tabs__item:hover {
  color: rgb(1, 175, 174);
}

.el-form-item {
  margin-bottom: 20px;
}

.opDialog form {
  margin: auto;
}

/deep/.el-dialog {
  width: 400px;
  border-radius: 10px;
}

/deep/.el-dialog__header {
  padding: 0;
  padding-top: 10px;
  text-align: center;
}

/deep/.el-dialog__title {
  font-size: 18px;
  font-weight: 600;
  color: rgb(12, 123, 123);
}

/deep/.el-dialog__body {
  padding: 0px 20px;
  margin: auto;
  margin-top: 25px;
}

/deep/.opDialog input {
  width: 260px;
}
</style>
