<template>
  <div class="usersManage">
    <div class="concent">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
        <el-breadcrumb-item :to="{ path: '/adminHome' }"
          >首页</el-breadcrumb-item
        >
        <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="search">
        <div style="margin-bottom: 15px">
          <el-input
            placeholder="请输入用户ID"
            size="small"
            v-model="inputId"
            style="width: 150px; margin-right: 10px"
            clearable
          >
          </el-input>
          <el-input
            placeholder="请输入用户名"
            size="small"
            v-model="inputName"
            style="width: 150px; margin-right: 10px"
            clearable
          >
          </el-input>
          <el-button
            type="primary"
            size="small"
            icon="el-icon-search"
            @click="search"
            >搜索</el-button
          >
        </div>
      </div>
      <el-table
        ref="filterTable"
        :data="tableData.slice(startIndex, endIndex)"
        style="width: 100%"
      >
        <el-table-column prop="id" label="用户编号" width="80">
        </el-table-column>
        <el-table-column
          prop="name"
          label="用户名"
          width="120"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="password" label="密码" width="120px">
        </el-table-column>
        <el-table-column prop="sex" label="性别" width="50px">
        </el-table-column>
        <el-table-column prop="time" label="出生日期" width="100px">
        </el-table-column>
        <el-table-column prop="phone" label="手机号" width="120px">
        </el-table-column>
        <el-table-column prop="major" label="专业" width="160px">
        </el-table-column>
        <el-table-column prop="place" label="地区"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
              >注销</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :page-size="pageSize"
        :total="this.tableData.length"
        :current-page.sync="currentPage"
        @current-change="handlePageChange"
        layout="prev, pager, next"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import * as echarts from "echarts";
import $ from "jquery";
export default {
  data() {
    return {
      inputId: "",
      inputName: "",
      currentPage: 1, // 当前页码
      pageSize: 7, // 每页显示的行数
      tableData: [
        {
          id: "0001",
          name: "张三",
          password: "123456",
          sex: "男",
          time: "2024-6-1",
          phone: "1388888888",
          major: "计算机科学与技术",
          place: "北京朝阳区",
        },
        {
          id: "0001",
          name: "张三",
          password: "123456",
          sex: "男",
          time: "2024-6-1",
          phone: "1388888888",
          major: "计算机科学与技术",
          place: "北京朝阳区",
        },
        {
          id: "0001",
          name: "张三",
          password: "123456",
          sex: "男",
          time: "2024-6-1",
          phone: "1388888888",
          major: "计算机科学与技术",
          place: "北京朝阳区",
        },
        {
          id: "0001",
          name: "张三",
          password: "123456",
          sex: "男",
          time: "2024-6-1",
          phone: "1388888888",
          major: "计算机科学与技术",
          place: "北京朝阳区",
        },
        {
          id: "0001",
          name: "张三",
          password: "123456",
          sex: "男",
          time: "2024-6-1",
          phone: "1388888888",
          major: "计算机科学与技术",
          place: "北京朝阳区",
        },
        {
          id: "0001",
          name: "张三",
          password: "123456",
          sex: "男",
          time: "2024-6-1",
          phone: "1388888888",
          major: "计算机科学与技术",
          place: "北京朝阳区",
        },
        {
          id: "0001",
          name: "张三",
          password: "123456",
          sex: "男",
          time: "2024-6-1",
          phone: "1388888888",
          major: "计算机科学与技术",
          place: "北京朝阳区",
        },
        {
          id: "0001",
          name: "张三",
          password: "123456",
          sex: "男",
          time: "2024-6-1",
          phone: "1388888888",
          major: "计算机科学与技术",
          place: "北京朝阳区",
        },
      ],
    };
  },
  mounted() {
    this.getTableData();
  },
  created() {
    this.getTableData();
  },
  computed: {
    //分页功能
    startIndex() {
      // 计算起始位置
      return (this.currentPage - 1) * this.pageSize;
    },
    endIndex() {
      // 计算结束位置
      return this.startIndex + this.pageSize;
    },
  },
  methods: {
    handlePageChange(page) {
      // 处理分页器页码变化事件
      this.currentPage = page;
    },

    handleEdit(index, row) {
      console.log(index, row);
    },
    handleDelete(index, row) {
      console.log(index, row);
    },

    //获取表数据
    getTableData() {
      $.ajax({
        type: "get",
        url: "http://47.93.13.70:8081/user/show",
        contentType: "application/json;charset=utf-8",
        dataType: "json",
        success: (res) => {
          this.tableData = [];
          for (var i = 0; i < res.length; i++) {
            this.tableData.push({
              id: res[i].id,
              name: res[i].username,
              password: res[i].password,
              sex: res[i].sex,
              time: res[i].birthdate,
              phone: res[i].phone,
              major: res[i].profession,
              place: res[i].region,
            });
          }
        },
        error: (res) => {
          this.$message.error("获取数据失败！");
        },
      });
    },
    //搜索方法
    search() {
      var id = this.inputId;
      var username = this.inputName;
      $.ajax({
        type: "get",
        url: "http://47.93.13.70:8081/user/search",
        contentType: "application/json;charset=utf-8",
        dataType: "json",
        data: {
          id,
          username,
        },
        success: (res) => {
          var data = JSON.stringify(res);
          data = JSON.parse(data);
          this.tableData = [];
          for (var i = 0; i < data.length; i++) {
            //console.log(data[i].name)
            this.tableData.push({
              id: res[i].id,
              name: res[i].username,
              password: res[i].password,
              sex: res[i].sex,
              time: res[i].birthdate,
              phone: res[i].phone,
              major: res[i].profession,
              place: res[i].region,
            });
          }
        },
        error: (res) => {
          this.$message.error("该用户不存在！");
        },
      });
    },
    //删除方法
    handleDelete(index, row) {
      var id = row.id;
      // 弹出确认删除的对话框
      if (confirm("确认要注销这个用户？")) {
        $.ajax({
          type: "get",
          url: "http://47.93.13.70:8081/user/delete",
          contentType: "application/json;charset=utf-8",
          data: { id },
          success: (res) => {
            this.$message.success("注销成功！");
            this.getTableData();
          },
          error: (res) => {
            this.$message.error("注销失败，请检查！");
          },
        });
      } else {
        this.$message.info("注销操作已取消！");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.breadcrumb {
  margin-bottom: 15px;
  margin-top: 10px;
}

.usersManage {
  width: 95%;
  margin: auto;
}

.concent {
  width: 100%;
  margin: auto;
  height: 500px;
}
</style>
