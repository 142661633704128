<template>
    <div class="assistant">
        <div class="scroll">

            <el-col :span="24">
                <el-card shadow="always" class="container">
                    <div class="content">

                    </div>
                    <div class="search">
                        <div class="gpt"><img src="chatgpt.png" alt="" width="40px" height="40px"></div>
                        <div class="sInput"><el-input class="input" v-model="input" placeholder="Ask me anything..."
                                prefix-icon="el-icon-search"></el-input></div>
                        <div class="send" @click="search"><img src="send.png" alt="" width="40px" height="40px"></div>
                    </div>
                </el-card>
            </el-col>
        </div>
    </div>

</template>



<script>
import axios from 'axios';
import * as echarts from 'echarts';
import $ from 'jquery';
export default {
    data() {
        return {

        };

    },
    mounted() {

    },
    methods: {
        search() {

        }

    }

}
</script>

<style lang='less' scoped>
.scroll {

    width: 1300px;
    height: 600px;
    margin: auto;
    margin-top: 10px;
    border-radius: 10px;
    // background-color: sandybrown;
}

.container {
    height: 590px;

}

.content {
    height: 490px;
    width: 98%;
    margin: auto;
    margin-bottom: 20px;
    overflow: auto;
    /* 启用滚动条 */

}

.search {
    width: 1120px;
    height: 50px;
    margin: auto;
    display: flex;
    align-items: center;
}

/deep/.el-input__inner:focus {
    border-color: rgb(1, 175, 174);
}

.gpt {
    float: left;
    margin-right: 20px;
}

.sInput {
    width: 1000px;
    height: 40px;
    float: left;
    margin-right: 10px;
}

.send {
    width: 50px;
    height: 40px;
    text-align: center;
    cursor: pointer;
    float: left;
    /* 设置光标样式为小手 */
}
</style>