<template>
    <div class="myPost">
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
            <el-menu-item index="1"><i class="el-icon-s-claim"></i>我发布的案例</el-menu-item>
            <el-menu-item index="2"><i class="el-icon-video-camera-solid"></i>我发布的视频</el-menu-item>
        </el-menu>
        <div class="myPostContent">
            <router-view></router-view>
        </div>
    </div>
</template>



<script>
import axios from 'axios';
import * as echarts from 'echarts';
import $ from 'jquery';
export default {
    data() {
        return {
            activeIndex: '',
        }
    },
    mounted() {

    },
    methods: {
        //页面跳转
        handleSelect(index) {
            this.activeIndex = index;
            if (index == '1') {
                this.$router.push('/myPost/myPostCase')
            } else if (index == '2') {
                this.$router.push('/myPost/myPostVideo')
            }
        },
        getFace() {
            // 获取用户摄像头的视频流
            navigator.mediaDevices.getUserMedia({ video: true })
                .then(stream => {
                    this.$refs.video.srcObject = stream;
                })
                .catch(err => {
                    console.error('Error accessing the camera: ' + err);
                });
            // 创建一个canvas元素来绘制捕获的视频帧
            let video = this.$refs.video;
            let canvas = document.createElement('canvas');
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
            // 将捕获的面部信息转换为图像数据（Base64 编码）
            let imageData = canvas.toDataURL('image/png');
            console.log(imageData);
            // 发送图像数据到后端
            axios.post('/process-image', { imageData })
                .then(response => {
                    console.log(response.data);
                    //登录进去
                })
                .catch(error => {
                    console.error('Error sending image data to the server: ' + error);
                });
        },
    }
}
</script>

<style lang='less' scoped>
.myPost {
    width: 1200px;
    height: 100%;
    margin: auto;
}

/deep/.el-menu-item {
    font-size: 16px;
    font-weight: 600;
}

.myPostContent {
    width: 97%;
    height: 560px;
    margin: auto;
}

/deep/.el-menu--horizontal>.el-menu-item.is-active {
    border-bottom: 0px;
}
</style>